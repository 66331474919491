import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import { useSignsecureService } from "@src/ducks/hooks";
import React from "react";
import { Modal, Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";

const Intro = (props: any) => {
  const styles = useStyles();
  const { showShareDocument, setShowShareDocument  } = React.useContext(SignatureContext);

  const onProceed = () => {
    setShowShareDocument(false);
  };

  return (
    <View style={styles.introMargin}>
      <Text style={styles.introText}>
        Share "Letter of Transmittal Document"
      </Text>
      <Text style={styles.introText2}>
        Share to email
      </Text>
    </View>
  );
};

export default Intro;
