import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_body: { flex: 1, flexDirection: "row" },
    container: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    cardsSpacing:  {
      flex: 1,
      justifyContent: "center",
      alignItems: "center", width:"100%" 
    },

    t_title: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 43,
      lineHeight: 14, color: colors.black3, textAlign: "center"
    },
    t_notes: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 18,
      marginTop: 39,
      maxWidth: 629,
      textAlign: "center",
      color: colors.black3,
    },
    buttonStyling: {
      flexDirection: "row", 
      alignItems: "center"
    },
    labelStyle: { fontFamily: "DMSans-Bold" },
    btn_proceed: { width: 250, height: 47, marginTop: 80, backgroundColor: colors.blue },

    // COPY-RIGHT
    copyRight: { display: "none" },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute",
        alignSelf: "center",textAlign: "center", 
        color: colors.black },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButtonMobile]),
  },
  {
    [DEVICE_SIZES.LG]: {
      t_title: { fontSize: 18, lineHeight: 10.5, marginTop: 35, },
      t_notes: { fontSize: 12, lineHeight: 13.5, marginTop: 28, maxWidth: 450 },
      btn_proceed: { width: 187, height: 35, marginTop: 40, borderRadius: 6 },
      labelStyle: { fontSize: 10.5, lineHeight: 10.5 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      v_body: { flex: 1, flexDirection: "column" },
      container: {
        minWidth: "100%",
        maxWidth: "100%",
      },
      buttonStyling: {
        flexDirection: "column-reverse", 
        alignItems: "center"
      },
      btn_proceed: { width: 350, height: 47, marginTop: 46, backgroundColor: colors.blue },
      copyRight:{
        display:"flex",
        bottom: 0
      },
    }
    
  }
);

export default useStyles;
