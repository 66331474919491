import Icon from "@expo/vector-icons/MaterialIcons";
import Button from "@src/components/button/Button04";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService } from "@src/ducks/hooks";
import { selectSubscriptionActive,selectSubscriptionExpiration, selectSubscriptionFullSlot, selectSubscriptionMaxUsers, selectSubscriptionRole } from "@src/ducks/slices/user.slice";
import React from "react";
import { Text } from "react-native";
import { DrawerContext } from "../../drawer-provider";
import useStyles from "./styles.css";

const getButtonLabel = (title: string) => {
  switch(title){
  case "Signatures":
    return "Upload Signature";
  case "Manage Users":
    return "Add User";
  case "Inbox":
    return "Create Message";
  case "Dashboard":
  case "Documents":
  default:
    return "Create Document";
  }
};

const CreateButton = () => {
  const styles = useStyles();
  const { onDrawerEnable } = useDashboardService();
  const maxUser = useAppSelector(selectSubscriptionMaxUsers);
  const fullSlot = useAppSelector(selectSubscriptionFullSlot);
  const subscriptionRole = useAppSelector(selectSubscriptionRole);
  const isSubscribeActive = useAppSelector(selectSubscriptionActive);
  const isNotExpired = useAppSelector(selectSubscriptionExpiration);
  const { activeTab, onActiveTab, onSetActiveSecondScreen, onAddTeamButton } = React.useContext(DrawerContext);
  const ButtonLabel = React.useMemo(() => getButtonLabel(activeTab), [activeTab]);

  const onPress = React.useCallback(() => {
    switch(activeTab){
    case "Signatures":
      onSetActiveSecondScreen("signature-upload");
      break;
    case "Manage Users":
      onAddTeamButton(true);
      break;
    case "Dashboard":
    case "Documents":
    case "Support":
    case "Document Sent":
      onDrawerEnable(false);
      onActiveTab("Create Document");
      break;
    }
  },[activeTab]);

  if(isNotExpired && !isSubscribeActive){
  }
  else if(!isSubscribeActive && ["Dashboard", "Documents", "Manage Users"].includes(activeTab)){
    return null;
  }else if(!["Owner", "Admin"].includes(subscriptionRole) && ["Manage Users"].includes(activeTab)){
    return null;
  }else if(maxUser <=1 && ["Manage Users"].includes(activeTab)){
    return null;
  }else if(fullSlot && ["Manage Users"].includes(activeTab)){
    return null;
  }else if (activeTab === "Inbox"){
    return null; //for the mean time
  }

  return (
    <Button
      onPress={onPress}
      style={styles.btn_createdocument} >
      <>
        <Text style={styles.btn_label}>{ButtonLabel}</Text>
        <Icon name="add" size={15} color="white" />
      </>
    </Button>
  );
};

export default CreateButton;
