import colors from "@assets/colors";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { SignupInput } from "@src/ducks/types";
import { useValidation } from "@src/utils/validation-helper";
import _ from "lodash";
import React from "react";
import { View } from "react-native";
import Validator from "validator";
import { useSignupContext } from "../provider";
import useStyles from "./styles.css";

const EnterDetails = () => {
  const styles = useStyles();
  const { state, onDispatch, setActiveIndex } = useSignupContext();
  const { errorInput: error, checkValidation, onSetError } = useValidation();

  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    onSetError({} as SignupInput);
  };

  const handleSubmit = () => {
    const requiredFields = [
      "email",
      "givenName",
      "lastName",
      "password",
      "confirmPassword",
    ];

    const newError = checkValidation(requiredFields, state);


    if (state.email && !Validator.isEmail(state.email)) {
      newError.email = "Invalid email format";
    }

    if (state.password.length <= 5) {
      newError.password = "Password must be at least six characters";
    }

    onSetError(newError);

    if (_.isEmpty(newError)) {
      setActiveIndex(1);
    }
  };

  return (
    <View style={styles.v_signup}>
      <View style={styles.v_field}>
        <TextInput02
          label="Email"
          value={state.email}
          error={error.email}
          style={styles.v_flex1}
          placeholder="Enter your email"
          onChangeText={onChangeText("email")}
        />
        <TextInput02
          label="First Name"
          value={state.givenName}
          error={error.givenName}
          style={styles.v_flex1}
          placeholder="First Name"
          onChangeText={onChangeText("givenName")}
        />
        <TextInput02
          label="Last Name"
          style={styles.v_flex1}
          value={state.lastName}
          error={error.lastName}
          placeholder="Last Name"
          onChangeText={onChangeText("lastName")}
        />
        <TextInput02
          hasPassword
          label="Password"
          style={styles.v_flex1}
          value={state.password}
          error={error.password}
          placeholder="Password"
          onChangeText={onChangeText("password")}
        />
        <TextInput02
          hasPassword
          label="Confirm Password"
          style={styles.v_flex1}
          value={state.confirmPassword}
          error={error.confirmPassword}
          placeholder="Confirm Password"
          onChangeText={onChangeText("confirmPassword")}
        />
        <View style={styles.v_height} />
        <Button01
          label="Next"
          color={colors.white}
          onPress={handleSubmit}
          style={styles.btn_signup}
          labelStyle={styles.lbl_signup}
        />
      </View>
    </View>
  );
};

export default EnterDetails;
