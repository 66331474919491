import AffixIcon from "@assets/svg/affix-signature";
import { useNavigation, useRoute } from "@react-navigation/native";
import Button from "@src/components/button/Button01";
import React from "react";
import { Text, View } from "react-native";
import CopyRightContent from "./copy-right";
import useStyles from "./styles.css";

const RightContent = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const navigation = useNavigation<any>();

  const onPress = () =>  {
    if(route.name === "payment-success"){
      navigation.navigate("Signin");
    }else{
      navigation.navigate("PromoSignup", { plan: "pro"});
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.cardsSpacing}>
        <AffixIcon name={route.name === "payment-success" ? "card-success" : "card-unsuccess"} />
        <Text style={[styles.t_title]}>
          {route.name === "payment-success" ? "Payment successful!" : "Payment unsuccessful"}
        </Text>
        <Text style={styles.t_notes}>
        {route.name === "payment-success" ? (
          <>
            Thank you for choosing <Text style={{ fontWeight: 'bold' }}>UNAWA SignSecure</Text>. Enjoy enhanced features and keep your workflows running smoothly.
          </>
        ) : (
          <>
            Unfortunately, we couldn't process your payment at this time. Please try again or contact support for assistance. Thank you for choosing <Text style={{ fontWeight: 'bold' }}>UNAWA SignSecure</Text>.
          </>
        )}
      </Text>
        <View style={styles.buttonStyling}>
          <Button
            onPress={onPress}
            label={route.name === "payment-success" ? 
            "Sign in now to get started!" : "Go back to Payment"}
            style={styles.btn_proceed}
            labelStyle={styles.labelStyle}
          />
        </View>
      </View>
      <View style={styles.copyRight}>
        <CopyRightContent />
      </View>
    </View>
  );
};

export default RightContent;
