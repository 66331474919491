import colors from "@assets/colors";
import { useRoute } from "@react-navigation/native";
import { useAuthContext } from "@src/AuthProvider";
import { Button01 } from "@src/components/button";
import Loading from "@src/components/loading";
import { useFetchGet } from "@src/utils/api/hooks";
import { convertStringBreakline } from "@src/utils/transform-helper";
import _ from "lodash";
import numeral from "numeral";
import React, { useEffect } from "react";
import { Text, View } from "react-native";
import { useSignupContext } from "../provider";
import useStyles from "./styles.css";

const PLANCODES: any = {
  pro: "Professional",
  "pro-12": "Professional",
  biz: "Business",
  "biz-12": "Business",
};

const ReviewPlan = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const code = route?.params?.plan;
  const { onErrorMessage } = useAuthContext();
  const { data, loading, error, runRequest } = useFetchGet();
  const { state, subscriptionPlan, setSubscriptionPlan, setActiveIndex } = useSignupContext();

  const getAmountCost = React.useMemo(() => {
    const totalCost = numeral(subscriptionPlan.cost).format("0,000");
    return `₱${totalCost}`
  },[subscriptionPlan])

  useEffect(() => {
    runRequest("/subscription-plans/all");
  }, []);

  useEffect(() => {
    if(!_.isEmpty(data?.data?.monthly)){
      const subscriptions = ["pro-12","biz-12"].includes(code) 
        ? data?.data?.annual ?? [] : data?.data?.monthly ?? [];
      const result = subscriptions.find((item: any) => {
        return item?.name === PLANCODES[code];
      });
      setSubscriptionPlan(result);
    }

    if(!_.isEmpty(error?.message)){
      onErrorMessage(error.message)
    }
  }, [data, code, error]);

  if(loading){
    return <Loading />
  }

  return (
    <View style={styles.os_container}>
      <Text style={styles.t_order_summary}>Order Summary</Text>
      <Text style={[styles.t_document_title, styles.t_add_margin]}>Please confirm if all details are correct.</Text>
      <View style={styles.v_divider} />
      <Text style={styles.t_order_summary}>{subscriptionPlan.name}</Text>
      <Text style={[styles.t_package_details, styles.t_add_margin]}>
        {convertStringBreakline(subscriptionPlan.pageDescription)}
      </Text>
      <View style={styles.v_divider2} />
      <View style={[styles.v_item_rows]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Email</Text>
        <Text style={styles.t_document_title}>{state.email}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Plan</Text>
        <Text style={styles.t_document_title}>{subscriptionPlan.name}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Billing</Text>
        <Text style={styles.t_document_title}>{subscriptionPlan.paymentMode}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Amount</Text>
        <Text style={styles.t_document_title}>{getAmountCost}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={styles.t_total} />
        <Text style={[styles.t_total]}>Total Due    {getAmountCost}</Text>
      </View>
      <View style={styles.v_divider2} />
      <Button01
        label="Proceed to Payment"
        color={colors.white}
        onPress={() => setActiveIndex(2)}
        style={styles.btn_proceed}
        labelStyle={styles.lbl_proceed}
      />
    </View>
  );
};

export default ReviewPlan;
