import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { getActiveIndex } from "@src/utils/filter-helper";
import { LiveRequestSteps, PromoSignup } from "@src/utils/mockdata";
import React from "react";
import { Pressable, Text, View } from "react-native";
import Items from "./items";
import useStyles from "./styles.css";
import Icon from "@expo/vector-icons/MaterialIcons";
import colors from "@assets/colors";
import { useSignupContext } from "../provider";

const HeaderSteps = () => {
  const styles = useStyles();
  const { activeIndex, setActiveIndex } = useSignupContext();
  
  return (
    <>
      {activeIndex > 0 && <Pressable
        onPress={() => setActiveIndex(activeIndex - 1)}
        style={styles.btn_style}
      >
        <Icon name="west" color={colors.grayPrimary} size={18} />
        <Text selectable={false} style={styles.t_back}>{"Back"}</Text>
      </Pressable>}
      <View style={styles.steps_container}>
        {PromoSignup.map((item, index) => {
          return (
            <Items
              key={`stepIdx${index}`}
              item={item}
              disabled={activeIndex < index}
              isActive={activeIndex === index}
              isDone={activeIndex > index}
              // isDone={true}
              onPress={() => {console.log("ss")}}
            />
          );
        })}
      </View>
    </>
  );
};

export default React.memo(HeaderSteps);
