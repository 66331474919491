import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";

export const STEPS_HEADER_HEIGHT = 91;

const useStyles = CreateResponsiveStyle(
  {
    steps_container: {
      marginTop: 93,
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    v_align_center: {
      minWidth: 135,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    outer_circle: {
      height: 45,
      width: 45,
      borderRadius: 45 / 2,
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 3,
      borderColor: colors.grayPrimary
    },
    steps_label: {
      fontFamily: "DMSans-Medium",
      fontSize: 14,
      lineHeight: 19,
      marginTop: 13,
      textAlign: "center",
      color: colors.grayPrimary,
    },


    btn_style: {
      position: "absolute",
      top: 53,
      left: 60,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    t_back: { fontFamily: "DMSans-Bold", color: colors.grayPrimary, fontSize: 14, marginLeft: 14 },
  },
  {
    [DEVICE_SIZES.LG]: {
      steps_container: {
        marginTop: 80
      },
      outer_circle: {
        height: 33.75,
        width: 33.75,
        borderRadius: 33.75 / 2,
        borderWidth: 2.25,
      },
      v_align_center: { minWidth: 105 },
      steps_label: { fontSize: 10.5, lineHeight: 14, marginTop: 7},
      btn_style: {
        top: 43,
        left: 45,
      },
      t_back: { fontSize: 12, marginLeft: 8 },
    },
  }
);

export default useStyles;
