import SVGDashboard from "@assets/svg/dashboard";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Text, TouchableOpacity, View, Pressable } from "react-native";
import Animated, { runOnJS, useSharedValue, withTiming } from "react-native-reanimated";
import Support from "../support/index";
import { DrawerContext } from "./drawer-provider";
import useStyles from "./styles.css";
import useOrientationHelper from "@src/utils/orientation-helper";


const SupportButton = () => {
  const styles = useStyles();
  const isMobile = mobileSize();
  const isTabletSize = tabletSize();
  const animated = useSharedValue(0);
  const animatedScroll = useSharedValue(0);
  const chatboxRef = useRef<HTMLDivElement>(null);
  const drawerEnable = useAppSelector(selectedDrawerStatus);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { activeTab, onActiveTab } = useContext(DrawerContext);
  const isLandScape = useOrientationHelper();
  const [focus, setFocus] = useState(false);

  const toggleSupportChat = () => {
    if(isMobile) {
      onActiveTab("Support");
      setIsVisible(false);
    } else {
      if(isVisible){
        animated.value = withTiming(0, { duration: 300 },
          (finished) => {
            if (finished) runOnJS(setIsVisible)(!isVisible);
          });
      }else{
        setIsVisible(!isVisible);
        animated.value = withTiming(1, { duration: 300 });
      }
    }
  };

  useEffect(() => {
    animatedScroll.value = withTiming(drawerEnable ? 360 : 110, { duration: 400 });
  },[drawerEnable]);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (chatboxRef.current && !chatboxRef.current.contains(event.target as HTMLDivElement)) {
      setIsVisible(false);
    }
  };

  if(activeTab === "Affix Document" || (activeTab === "Support")){
    return null;
  }

  return (
    <View style={[styles.chatSupportBtn]} >
      <Pressable 
          style={styles.chatButton}
          onPress={toggleSupportChat} 
          onHoverIn={() => setFocus(true)}
          onHoverOut={() => setFocus(false)}>

        {focus 
          ?         
            <View style={styles.contentButtonFocused}>
              <SVGDashboard style={{alignItems: "center"}} name="chat" />
              <Text style={styles.signSecureSupport}>SignSecure Support</Text>
            </View> 
          :         
            <View style={styles.contentButton}>
              <SVGDashboard style={{alignItems: "center"}} name="chat" />
            </View>
        }

        {isVisible && (
          <View>
            <Animated.View 
              style={[
                styles.chatContainer, 
                isTabletSize && (isLandScape ? styles.tabletContainerLandscape : styles.tabletContainer)
            ]} >
              <TouchableOpacity onPress={(event) => event.stopPropagation()} activeOpacity={1}>
                <Support ref={chatboxRef} setIsVisible={setIsVisible} isVisible={isVisible} />
              </TouchableOpacity>
            </Animated.View>
          </View>     
        )}
      </Pressable>
    </View>
  );
};

export default React.memo(SupportButton);
