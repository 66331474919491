import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1 },
    v_body: { flex: 1, flexDirection: "row" },
    v_screens: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    linearStyle_portrait: { display: "none" },
    v_logo_top: { alignItems: "center", height: 300, justifyContent: "center", display: "none" },
    v_logo_top_2: { alignItems: "center", justifyContent: "center", display: "none" },


    v_privacy: { display: "none", marginTop: 52 },

    // LOGO COMPONENT
    v_logo_container: {
      flex: 1,
      width: "50%",
      alignItems: "flex-start",
      justifyContent:"flex-start",
      paddingLeft: 125,
      paddingTop: 257
    },
    txtNote3: {
      fontFamily: "DMSans",
      fontWeight: "700",
      fontSize: 33,
      color: colors.black2,
      paddingTop: "15%"
    },
    txtNote4: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 18,
      color: colors.black2,
      marginTop: 30
    },
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.gray4,
      marginTop: 10,
      width: 190
    },
    txtNote5: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      color: colors.black2,
    },
    i_logo: { width: 620, height: 60 },
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "500",
      paddingTop: 0,
      fontSize: 50,
      lineHeight: 55,
      marginTop: 27,
      color: colors.white, 
    },
    t_experience: {
      fontFamily: "DMSans",
      paddingTop: 0,
      fontSize: 31,
      lineHeight: 38,
      marginTop: 21,
      color: colors.white, 
    },

    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: 100,
        alignSelf: "center",textAlign: "center", 
        color: colors.white },
    ]),
    unawaProductStyle : StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { position: "absolute", bottom: "15%",
        alignSelf: "flex-start",textAlign: "flex-start", 
        color: colors.white },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
    },
  },
  {
    [DEVICE_SIZES.XL]: {
    },
    [DEVICE_SIZES.LG]: {
      i_logo: { width: 373, height: 120.86 },
      t_logo: { fontSize: 40, lineHeight: 45, marginTop: 21 },
      t_experience: { fontSize: 24, lineHeight: 28, marginTop: 19 },
      copyRightStyle: { fontSize: 10.5, lineHeight: 15.75 },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },
      itemSeparator : {
        width: "30%"
      }
    },
    [DEVICE_SIZES.MD]: {
      v_body: { flex: 1, flexDirection: "column" },
      v_logo_container: {
        width: "20%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      t_logo: { 
        fontSize: 32, 
        lineHeight: 35, 
        marginTop: 27, 
        fontWeight: "500",
        textAlign: "center"
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },

      //portrait
      v_screens: {
        minWidth: "100%",
        maxWidth: "100%",
      },
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex", height: 300 },
      
      itemSeparator: {
        width: "30%"
      },
    }, 
    [DEVICE_SIZES.SM]: {
      v_body: { flex: 1, flexDirection: "column" },
      v_logo_container: {
        width: "20%"
      },
      itemSeparator: {
        width: "30%"
      },
      t_logo: { 
        fontSize: 16, 
        lineHeight: 25, 
        marginTop: 40, 
        fontWeight: "500",
        textAlign: "center"
      },
      txtNote4: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 16,
        color: colors.black2,
        marginTop: 30
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },

      //portrait
      v_screens: {
        minWidth: "100%",
        maxWidth: "100%",
      },
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex" },
      
    },
    [DEVICE_SIZES.XS]: {
      v_body: { flex: 1, flexDirection: "column" },
      v_logo_container: {
        width: "20%"
      },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 40, 
        fontWeight: "500",
        textAlign: "center"
      },
      txtNote4: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 14,
        color: colors.black2,
        marginTop: 30
      },
      copyRightBtnStyle: { fontSize: 10.5, lineHeight: 15.75 },

      //portrait
      v_screens: {
        minWidth: "100%",
        maxWidth: "100%",
        height: "80%"
      },
      txtNote3: {
        fontFamily: "DMSans",
        fontWeight: "700",
        fontSize: 33,
        color: colors.black2,
        paddingTop: "0%"
      },
      unawaProductStyle : StyleSheet.flatten([
        TypographyStyle.copyRightText,
        { position: "relative", bottom: 0, top: 50,
          alignSelf: "center",textAlign: "center", 
          color: colors.black },
      ]),
      linearStyle_portrait: { display: "none" },
      v_logo_top: { display: "flex" },
      itemSeparator: {
        width: "30%"
      },
      txtNote5: {
        fontFamily: "DMSans",
        fontWeight: "400",
        fontSize: 14,
        color: colors.black2,
      },
    },
  }
);

export default useStyles;