import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    os_container: {
      width: "100%",
      marginTop: 50,
      paddingHorizontal: "7%",
      backgroundColor: colors.white,
    },
    t_order_summary: {
      color: colors.black3,
      fontFamily: "DMSans-Bold",
      fontSize: 18,
      lineHeight: 23,
    },
    t_os_note: {
      marginTop: 15,
      color: colors.gray9,
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 18,
    },
    v_divider: {
      height: 1,
      marginTop: 23,
      marginBottom: 30,
      backgroundColor: colors.gray10,
    },
    v_divider2: {
      height: 1,
      marginTop: 20,
      marginBottom: 20,
      backgroundColor: colors.gray10,
    },
    v_divider3: {
      height: 1,
      marginTop: 88,
      marginBottom: 25,
      backgroundColor: colors.gray10,
    },
    t_package_details: {
      color: colors.black3,
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 18,
    },
    t_document_title: {
      color: colors.black3,
      fontFamily: "Lato",
      fontSize: 16,
      lineHeight: 20,
    },
    v_flex1: { width: "25%", marginTop: 0 },
    borderStyle: { height: 48 },
    t_add_margin: { marginTop: 7 },
    v_item_rows: {flexDirection: "row", alignItems: "center", justifyContent: "space-between"},
    add_row_margin_top: { marginTop: 0 },
    add_row_margin_top2: { marginTop: 28 },
    t_font_bold: { fontFamily: "Lato-Bold" },
    t_total: {
      color: colors.blue,
      fontFamily: "DMSans-Bold",
      fontSize: 18,
      lineHeight: 24,
    },
    t_change_plan: { 
      fontFamily: "DMSans-Bold",
      fontSize: 16,
      lineHeight: 21,
      marginTop: 30,
      color: colors.blue,
      textAlign: "center",
    },

    // 30days trial modal
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    v_modal: {
      width: 576,
      height: 300,
      paddingHorizontal: 55,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    t_title: {fontFamily: "DMSans-Bold", fontSize: 24,
      lineHeight: 28, color: colors.blue, textAlign: "center",},
    t_message: {fontFamily: "DMSans", fontSize: 16, marginTop: 25,
      lineHeight: 21, color: colors.black3, textAlign: "center",},
    btn_cancel: { width: 156, height: 48, marginTop: 46, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.blue },
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.blue },
    btn_proceed: { width: 156, height: 48, marginTop: 46, 
      paddingHorizontal: 0, backgroundColor: colors.blue },
    label_proceed: { fontFamily: "DMSans-Bold" },
    divider: { width: 22 },

  },
  {
    [DEVICE_SIZES.LG]: {
      os_container: {
        marginTop: 35,
      },
      t_order_summary: {
        fontSize: 16,
        lineHeight: 19,
      },
      t_os_note: {
        marginTop: 10,
        fontSize: 10.5,
        lineHeight: 14,
      },
      v_divider: {
        height: 0.75,
        marginTop: 20,
        marginBottom: 25,
      },
      v_divider2: {
        height: 0.75,
        marginTop: 15,
        marginBottom: 15,
      },
      v_divider3: {
        height: 0.75,
        marginTop: 60,
        marginBottom: 19,
      },
      t_package_details: {
        fontSize: 11,
        lineHeight: 15,
      },
      t_document_title: {
        fontSize: 12,
        lineHeight: 16,
      },
      t_total: {
        fontSize: 14,
        lineHeight: 20,
      },
      borderStyle: { height: 40 },
      add_row_margin_top: { marginTop: 10 },
      add_row_margin_top2: { marginTop: 20 },
      t_change_plan: { 
        fontSize: 12,
        lineHeight: 16,
        marginTop: 23,
      },


      v_modal: { width: 492, height: 250, paddingHorizontal: 40 },
      t_title: { fontSize: 18, lineHeight: 22 },
      t_message: { fontSize: 12, lineHeight: 17, marginTop: 20 },
      btn_proceed: { width: 140, height: 35, marginTop: 40, borderRadius: 6 },
      btn_cancel: { width: 140, height: 35, marginTop: 40, borderRadius: 6 },
      label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      label_cancel: { fontSize: 10.5, lineHeight: 10.5 },
    },
    [DEVICE_SIZES.MD]: {
      os_container: {
        paddingHorizontal: "12%",
      }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      os_container: {
        flex: 1,
        width: "100%",
        marginTop: 60,
        borderRadius: 0,
        paddingHorizontal: 20,
      },
      add_row_margin_top: { marginTop: 10 },

      v_modal: { width: "95%", height: 260, paddingHorizontal: 20 },
      t_title: { fontSize: 20, lineHeight: 24 },
      t_message: { fontSize: 13, lineHeight: 19, marginTop: 20 },
      btn_proceed: { width: 140, height: 35, marginTop: 40, borderRadius: 6 },
      btn_cancel: { width: 140, height: 35, marginTop: 40, borderRadius: 6 },
      label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      label_cancel: { fontSize: 10.5, lineHeight: 10.5 },

    },
  }
);

export default useStyles;
