import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import { useSignsecureService } from "@src/ducks/hooks";
import React from "react";
import { Modal, Text, View, Pressable, TouchableOpacity } from "react-native";
import { DocumentContext } from "@src/screens/create-document/provider/document";
import useStyles from "./styles.css";
import colors  from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import SVGIcon from "@assets/svg";

const Checkbox = (props: any) => {
  const styles = useStyles();
  const { checkBoxTick } = React.useContext(DocumentContext);
  

  return (
    <View style={styles.share_docs_wrapper}>
        <Pressable 
          style={[styles.share_docs_box, checkBoxTick && {backgroundColor: colors.black3}]} >
          <Icon name="check" size={15} color="white" />
        </Pressable>
        <View>
          <Text><b>Share document to anyone with a link</b></Text>
          <Text style={{fontSize: 12, fontWeight: "400"}}>Anyone on the internet with a link can view the file</Text>
        </View>
        <View style={{flexDirection: "row", left: "5%"}}>
          <TouchableOpacity activeOpacity={0.8}>
              <Icon name="content-copy" size={18} color={colors.blue2} />
          </TouchableOpacity>
          <Text style={styles.copyLinkText}>Copy Link</Text>
        </View>
      </View>
  );
};

export default Checkbox;
