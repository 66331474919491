import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { Linking, Text } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DrawerComponentContext } from "./provider";
import useStyles from "./styles.css";

const CopyRightContent = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { animated } = React.useContext(DrawerComponentContext);
  const [ currentYear , setCurrentYear ] = useState("");

  useEffect (() => {
    const year = new Date().getFullYear();
    setCurrentYear(
      year + ""
    );
  }, []);

  const animateStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [360, 110],
      [1, 0],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
    };
  }, []);

  return (
    <Animated.View style={[styles.drawer_content_footer, animateStyle]}>
      <Text style={styles.copyRightStyle}>
        <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("terms-condition")}>Terms & Conditions</Text> |
        <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text>
        {"\n"}
        © { currentYear + " " }
        <Text style={styles.copyRightBtnStyle} onPress={() => Linking.openURL("https://www.unawa.asia")}>
          UNAWA
        </Text>. All rights reserved
        { "\n"}
        Ver. 2.0.5
      </Text>
    </Animated.View>
  );
};

export default CopyRightContent;
