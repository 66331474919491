import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import { largeSize } from "@src/utils/screensize-helper";
import React, { useRef } from "react";
import {
  FlatList,
  Modal,
  Pressable,
  Text,
  View
} from "react-native";
import MenuItem from "../header/affix-signature/menu-item";
import useStyles from "./styles.css";

export interface MeasureValue {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MenuDropdownProps {
  value?: string;
  visible: boolean;
  measures: MeasureValue;
  // options: string[];
  onClose: () => void;
  onSelected: (item: string) => void;
}

const ActionsMenu = (props: MenuDropdownProps) => {
  const styles = useStyles();
  const isLarge = largeSize();
  const flatlist = useRef(null);
  

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <Pressable style={styles.modal} onPress={props.onClose} >
        <View style={[styles.dropdown]}>
          <Pressable
            onPress={props.onClose}
            style={styles.btn_other}>
            <Text selectable={false} style={styles.btn_label_other}>Otheddddr Actions</Text>
            <Icon name="expand-more" size={18} color={colors.black3} />
          </Pressable>
          <FlatList
            ref={flatlist}
            style={styles.flatlist}
            data={["Data", "Data2"]}
            keyExtractor={(_, index) => `${index}`}
            ItemSeparatorComponent={() => <View style={styles.item_separator} />}
            renderItem={({ item, index }: any) => {
              return (
                <MenuItem
                  key={`${index}`}
                  item={item}
                  onPress={() => {
                    props.onSelected(item);
                    props.onClose();
                  }} />
              );
            }}
          />
        </View>
      </Pressable>
    </Modal>
  );
};

export default ActionsMenu;
