import React, { useMemo } from "react";
import { View } from "react-native";
import EnterDetails from "./enter-details";
import HeaderSteps from "./header-steps";
import MakePayment from "./make-payments";
import { useSignupContext } from "./provider";
import ReviewPlan from "./review-plan";
import useStyles from "./styles.css";

const Screens = () => {
  const styles = useStyles();
  const { activeIndex } = useSignupContext();

  const components = useMemo(() => {
    switch (activeIndex) {
    case 0:
      return <EnterDetails />;
    case 1:
      return <ReviewPlan />;
    case 2:
      return <MakePayment />;
    default:
      return <EnterDetails />;
    }
  },[activeIndex]);

  return (
    <View style={styles.v_screens} >
      <HeaderSteps />
      {components}
    </View>
  )
};



export default Screens;
