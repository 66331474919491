import DMSansBold from "@assets/fonts/DMSans-Bold.ttf";
import DMSans from "@assets/fonts/DMSans.ttf";
import SignSecureV2Black from "@assets/images/auth/signsecure-black-logo.png";
import SignSecureV2 from "@assets/images/auth/signsecurev2_lowopacity.png";
import IconCheck from "@assets/images/ic_check.png";
import SignBackground from "@assets/images/sign_background.png";
import fontkit from "@pdf-lib/fontkit";
import { nanoid } from "@reduxjs/toolkit";
import { QRStyling } from "@src/components/qr-code";
import moment from "@src/utils/moment-timezone-helper";
import _ from "lodash";
import { PDFDocument, PageSizes, rgb } from "pdf-lib";
import {
  generateQRCodePosition,
  getAuthorFromAuditTrail, getCompletedFromAuditTrail, getPartiesApprove,
  getPartiesHasExecute, getSignatoryLabels, getSinatoryAndApproverValue, getTextConfig,
  signCheckBoxPosition, signLogoAndDatePosition, signSingleTextPosition,
  signTextBoxPosition, signTextPosition, signaturePosition
} from "./filter-helper";
import { characterTrim, isJPEGFormat } from "./validation-helper";

const headerColor = rgb(0.9607843137254902, 0.9607843137254902, 0.9607843137254902);
const textColor = rgb(0.12941176470588237, 0.13333333333333333, 0.12941176470588237);
const textColorAffix = rgb(0.6470588235294118,0.6470588235294118,0.6470588235294118);
const TOTAL_SIGNATORY_HEIGHT = 120;
const TOP_HEIGHT_DIVIDER = 12;

export const getCorner = (
  pivotX: number,
  pivotY: number,
  diffX: number,
  diffY: number,
  angle: number
) => {
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);

  /// find angle from pivot to corner
  angle += Math.atan2(diffY, diffX);

  /// get new x and y and round it off to integer
  const x = pivotX + distance * Math.cos(angle);
  const y = pivotY + distance * Math.sin(angle);

  return { x: x, y: y };
};

export const getClientBox = (rotatedBox: any) => {
  const { x, y, width, height } = rotatedBox;
  const rad = rotatedBox.rotation;

  const p1 = getCorner(x, y, 0, 0, rad);
  const p2 = getCorner(x, y, width, 0, rad);
  const p3 = getCorner(x, y, width, height, rad);
  const p4 = getCorner(x, y, 0, height, rad);

  const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    ...rotatedBox,
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const getTotalBox = (boxes: any) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  boxes.forEach((box: any) => {
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);
  });
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const scaleImage = (
  imageWidth: number,
  imageHeight: number,
  boxWidth: number,
  boxHeight: number
) => {
  // Calculate the aspect ratio of the image and the box
  const imageAspectRatio = imageWidth / imageHeight;
  const boxAspectRatio = boxWidth / boxHeight;

  let scaledWidth, scaledHeight;

  // Scale the image based on the aspect ratios
  if (imageAspectRatio > boxAspectRatio) {
    // The image is wider than the box
    scaledWidth = boxWidth;
    scaledHeight = boxWidth / imageAspectRatio;
  } else {
    // The image is taller than the box or has the same aspect ratio
    scaledWidth = boxHeight * imageAspectRatio;
    scaledHeight = boxHeight;
  }

  // Return the scaled dimensions
  return {
    width: scaledWidth,
    height: scaledHeight,
  };
};

export const submitOnlyAffixSignature = async (pdfFile: any, document: any, currentParty: any) => {
  const pdfDoc = await PDFDocument.load(pdfFile);
  const signatures = document?.workflow?.signatures ?? [];
  const DMSansFontBytes = await fetch(DMSans).then((res) => res.arrayBuffer());
  const SignSecureV2BlackBytes = await fetch(SignSecureV2Black).then((res) => res.arrayBuffer());
  const SignsecureV2Low = await fetch(SignSecureV2).then((res) => res.arrayBuffer());
  const SignBackgroundBytes = await fetch(SignBackground).then((res) => res.arrayBuffer());
  const IconCheckBytes = await fetch(IconCheck).then((res) => res.arrayBuffer());
  pdfDoc.registerFontkit(fontkit);

  const DMSansFont = await pdfDoc.embedFont(DMSansFontBytes);
  const SignBackgroundImage = await pdfDoc.embedPng(SignBackgroundBytes);
  const SignSecureV2LowImage = await pdfDoc.embedPng(SignsecureV2Low);

  signatures.forEach((item: any, index: number) => {
    const pageIndex = pdfDoc.getPage(index);

    item.forEach(async (field: any) => {
      let pngImage: any = null;
      if(field.signature && isJPEGFormat(field.signature)){
        pngImage = await pdfDoc.embedJpg(field.signature);
      }else if(field.signature){
        pngImage = await pdfDoc.embedPng(field.signature);
      }

      if (["signature", "initials"].includes(field.type) && field.signature) {
        const xy = signaturePosition(pngImage, field.config, pageIndex);
        pageIndex.drawImage(pngImage, {
          ...xy,
        });

        generateSignLogoAndID(pageIndex, field, document, SignSecureV2LowImage, SignBackgroundImage, DMSansFont);
      } else if (["signature-name", "signature-date"].includes(field.type) && field.signature) {
        const textConfig = getTextConfig(field);
        const xy = signaturePosition(pngImage, field.config, pageIndex);
        const text_xy = signTextPosition(field.config, textConfig, pageIndex);
        pageIndex.drawImage(pngImage, {
          ...xy,
        });
        pageIndex.drawText(textConfig.text, {
          ...text_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: textConfig.fontSize,
        });
        generateSignLogoAndID(pageIndex, field, document, SignSecureV2LowImage, SignBackgroundImage, DMSansFont);
      } else if (field.type === "signature-name-designation" && field.signature) {
        const xy = signaturePosition(pngImage, field.config, pageIndex);
        const name_xy = signTextPosition(field.config, field.nameConfig, pageIndex);
        const designation_xy = signTextPosition(field.config, field.designationConfig, pageIndex);
        pageIndex.drawImage(pngImage, {
          ...xy,
        });
        pageIndex.drawText(field.nameConfig.text, {
          ...name_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: field.nameConfig.fontSize,
        });
        pageIndex.drawText(field.designationConfig.text, {
          ...designation_xy,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          size: field.designationConfig.fontSize,
        });
        generateSignLogoAndID(pageIndex, field, document, SignSecureV2LowImage, SignBackgroundImage, DMSansFont);
      } else if (["full-name", "designation", "date-time"].includes(field.type)) {
        const textConfig = getTextConfig(field);
        const xy = signSingleTextPosition(field.config, textConfig, pageIndex);
        pageIndex.drawText(textConfig.text, {
          ...xy,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
        });
      } else if (["full-name", "designation", "date"].includes(field.type)) {
        const textConfig = getTextConfig(field);
        const xy = signSingleTextPosition(field.config, textConfig, pageIndex);
        pageIndex.drawText(textConfig.text, {
          ...xy,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
        });
      } else if (field.type === "textbox" && field.text) {
        const textConfig = getTextConfig(field);
        const xy = signTextBoxPosition(field.config, textConfig, pageIndex);

        pageIndex.drawText(field.text, {
          x: xy.x,
          y: xy.y,
          rotate: xy.rotate,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
        });
      } else if (field.type === "checkbox", field.trackId === currentParty.trackId) {
        const config = field.config;
        const checkConfig = field.checkConfig;
        const textConfig = getTextConfig(field);
        const IconCheckImage = await pdfDoc.embedPng(IconCheckBytes);
        const scaled = IconCheckImage.scaleToFit(checkConfig.width * 0.8, checkConfig.height);
        const xy = signCheckBoxPosition(config, textConfig, checkConfig, pageIndex, scaled);
        
        pageIndex.drawRectangle({
          x: xy.checkX,
          y: xy.checkY,
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          width: checkConfig.width,
          height: checkConfig.height,
          rotate: xy.rotate,
        });

        if(field.isChecked){
          pageIndex.drawImage(IconCheckImage, {
            x: xy.cx,
            y: xy.cy,
            rotate: xy.rotate,
            width: scaled.width,
            height: scaled.height,
          });
        }

        pageIndex.drawText(textConfig.text, {
          x: xy.textX,
          y: xy.textY,
          size: textConfig.fontSize,
          font: DMSansFont,
          color: rgb(0, 0, 0),
          rotate: xy.rotate,
        });
      }
    });
  });

  return await pdfDoc.save();
};

// CREATE SIGNATURE LOGO WITH ID AND DATES
const generateSignLogoAndID = (pageIndex: any, field: any, document: any, SignSecureV2LowImage: any, SignBackgroundImage: any, DMSansFont: any) => {
  const width = field.config.width;
  const heigh1_3rd = field.config.height * 0.3;
  const heigh1_7th = field.config.height * 0.7;
  const dateFontSize = heigh1_3rd * 0.18;
  const pos = signLogoAndDatePosition(field.config, heigh1_3rd, heigh1_7th, dateFontSize, pageIndex);

  pageIndex.drawImage(SignBackgroundImage, {
    x: pos.x,
    y: pos.y,
    width: width,
    height: heigh1_3rd,
    rotate: pos.rotate,
  });
  pageIndex.drawImage(SignSecureV2LowImage, {
    x: pos.logoX,
    y: pos.logoY,
    width: field.config.width * 0.308,
    height: heigh1_3rd * 0.6,
    rotate: pos.rotate,
  });
  pageIndex.drawText(`${document.id}  |  ${moment(field.signatureDate).format("YYYY.MM.DD  |  hh:mm:ss")}`, {
    x: pos.textX,
    y: pos.textY,
    font: DMSansFont,
    color: textColorAffix,
    size: dateFontSize,
    rotate: pos.rotate,
  });
};

export const completeSignatureWithCreateManifest = async (pdfFile: any, document: any) => {
  const pdfDoc = await PDFDocument.load(pdfFile);
  const qrcode: any = await QRStyling(200, 200, document.id);
  const DMSansFontBytes = await fetch(DMSans).then((res) => res.arrayBuffer());
  const DMSansBoldFontBytes = await fetch(DMSansBold).then((res) => res.arrayBuffer());
  pdfDoc.registerFontkit(fontkit);

  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);
  const DMSansFont = await pdfDoc.embedFont(DMSansFontBytes);
  const DMSansBoldFont = await pdfDoc.embedFont(DMSansBoldFontBytes);

  await qrcodePerPages(pdfDoc, document, QRCodeEmbed); // GENERATE ON THE FIRST CURRENT PAGES BEFORE CREATING MANIFEST
  await createManifest(pdfDoc, document, DMSansFont, DMSansBoldFont, QRCodeEmbed);

  return await pdfDoc.save();
};

export const createManifest = async (pdfDoc: any, document: any, DMSansFont: any, DMSansBoldFont: any, QRCodeEmbed: any) => {
  const SignSecureV2Bytes = await fetch(SignSecureV2).then((res) => res.arrayBuffer());

  const pageCount = pdfDoc.getPageCount(); // Get page count

  // Add a blank page to the document
  const page = pdfDoc.addPage(PageSizes.Letter);
  const { height } = page.getSize();  // Get the height of the page

  qrcodeSection(page, QRCodeEmbed, DMSansFont);

  // HEADER MANIFEST
  const SignSecureV2Embed = await pdfDoc.embedPng(SignSecureV2Bytes);
  page.drawImage(SignSecureV2Embed, {
    x: 499,
    y: height - 53,
    width: 88,
    height: 20,
  });
  page.drawText("Manifest", {
    x: 31,
    y: height - 53,
    size: 20,
    width: 69,
    font: DMSansBoldFont,
    color: rgb(0.6000, 0.6000, 0.6000),
  });

  await documentSection(page, document, DMSansFont, DMSansBoldFont, pageCount);// DOCUMENT SECTION
  const result = await signatorySection(page, pdfDoc, document, DMSansFont, DMSansBoldFont);// SIGNATORY SECTION

  if(result.height_per_signatory > 672){
    await approverSection({
      newpage: result.newpage,
      height_per_signatory: 100,
    }, pdfDoc, document, DMSansFont, DMSansBoldFont);// SIGNATORY SECTION
  }else{
    await approverSection(result, pdfDoc, document, DMSansFont, DMSansBoldFont);// SIGNATORY SECTION
  }

  return page;
};

// GENERATE QR CODE ON EACH PAGES
const qrcodePerPages = async (pdfDoc: any, document: any, QRCodeEmbed: any) => {
  const qrPosition = document.workflow.qrPosition;
  
  pdfDoc.getPages().forEach((item: any, index: number) => {
    const pageIndex = pdfDoc.getPage(index);
    
    const qrconfig = generateQRCodePosition(qrPosition, pageIndex);
    pageIndex.drawImage(QRCodeEmbed, qrconfig);
  });
};

// CREATE DOCUMENT SECTION
const documentSection = async (page: any, document: any, DMSansFont: any, DMSansBoldFont: any, pageCount: number) => {
  const { width, height } = page.getSize();
  const author = getAuthorFromAuditTrail(document);
  const audit = getCompletedFromAuditTrail(document);

  // HEADER BAR (DOCUMENT DETAILS)
  page.drawRectangle({
    x: 25,
    height: 30,
    y: height - 105,
    width: width - 50,
    color: headerColor,
  });
  page.drawText("Document Details", {
    x: 38,
    y: height - 96,
    size: 14,
    font: DMSansBoldFont,
    color: textColor,
  });

  // DOCUMENT DETAILS INFO
  page.drawText("Folder Name:", {
    x: 38,
    y: height - 127,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(characterTrim(document.folderName || "N/A"), {
    x: 102,
    y: height - 127,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("Document Title:", {
    x: 38,
    y: height - 147,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(characterTrim(document.name), {
    x: 113,
    y: height - 147,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("Date Created:", {
    x: 38,
    y: height - 167,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(moment(document.createdAt).format("MMMM DD, YYYY"), {
    x: 104,
    y: height - 167,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("Document Pages:", {
    x: 38,
    y: height - 187,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(String(pageCount), {
    x: 121,
    y: height - 187,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("Date of Completion:", {
    x: 38,
    y: height - 207,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(moment(audit.timestamp).format("MMMM DD, YYYY  |  hh:mm A ([GMT] Z)"), {
    x: 133,
    y: height - 207,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  // page.drawText("Time Zone:", {
  //   x: 38,
  //   y: height - 227,
  //   size: 10,
  //   font: DMSansFont,
  //   color: textColor,
  // });
  // page.drawText(author.timezone, {
  //   x: 90,
  //   y: height - 227,
  //   size: 10,
  //   font: DMSansBoldFont,
  //   color: textColor,
  // });
  page.drawText("Author:", {
    x: 337,
    y: height - 127,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(characterTrim(author?.author?.name ?? ""), {
    x: 373,
    y: height - 127,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("Email:", {
    x: 337,
    y: height - 147,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(characterTrim(author?.author?.email ?? ""), {
    x: 366,
    y: height - 147,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });
  page.drawText("IP Address:", {
    x: 337,
    y: height - 167,
    size: 10,
    font: DMSansFont,
    color: textColor,
  });
  page.drawText(author.ip, {
    x: 390,
    y: height - 167,
    size: 10,
    font: DMSansBoldFont,
    color: textColor,
  });

  return page;
};

const signatorySection = async (page: any, pdfDoc: any, document: any, DMSansFont: any, DMSansBoldFont: any) => {
  const { width, height } = page.getSize();
  const parties = getPartiesHasExecute(document);
  const documentId = document.id;
  const qrcode = await QRStyling(200,200,documentId);
  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);

  if(parties.length > 0){
  // HEADER BAR (DOCUMENT DETAILS)
    page.drawRectangle({
      x: 25,
      height: 30,
      y: height - 300,
      width: width - 50,
      color: headerColor,
    });
    page.drawText("Signatory", {
      x: 38,
      y: height - 291,
      size: 14,
      font: DMSansBoldFont,
      color: textColor,
    });
  }

  let count = 0;
  let newpage = page;
  let offset = 312;
  let height_per_signatory = 0;

  parties.forEach(async (value: any, key: number) => {
    height_per_signatory = (count * TOTAL_SIGNATORY_HEIGHT);
    const params: any = {
      value,
      DMSansFont,
      DMSansBoldFont,
    };

    if(height_per_signatory + offset > 672){
      count = 0;
      height_per_signatory = 0;
      offset = 100;
      newpage = pdfDoc.addPage(PageSizes.Letter);
      qrcodeSection(newpage, QRCodeEmbed, DMSansFont);
    }

    params.index = key;
    params.offset = offset;
    params.height_per_signatory = height_per_signatory;
    params.page = newpage;

    signatory(params);

    count += 1;
  });

  return {
    newpage,
    height_per_signatory: parties.length > 0 ? height_per_signatory + offset + TOTAL_SIGNATORY_HEIGHT + TOP_HEIGHT_DIVIDER : 352,
  };
};

const approverSection = async (result: any, pdfDoc: any, document: any, DMSansFont: any, DMSansBoldFont: any) => {
  const { newpage: page, height_per_signatory: new_height_per_signatory } = result;
  const { width, height } = page.getSize();
  const approver = getPartiesApprove(document);
  const documentId = document.id;
  const qrcode = await QRStyling(200, 200, documentId);
  const QRCodeEmbed = await pdfDoc.embedPng(qrcode);

  if(approver.length === 0){
    return;
  }

  // HEADER BAR (DOCUMENT DETAILS)
  page.drawRectangle({
    x: 25,
    height: 30,
    y: (height - new_height_per_signatory ) + 15,
    width: width - 50,
    color: headerColor,
  });
  page.drawText("Approver", {
    x: 38,
    size: 14,
    y: (height - new_height_per_signatory) + 15 + 9,
    font: DMSansBoldFont,
    color: textColor,
  });

  let count = 0;
  let newpage = page;
  let offset = new_height_per_signatory;
  let height_per_signatory = 0;

  approver.forEach(async (value: any, key: number) => {
    height_per_signatory = (count * TOTAL_SIGNATORY_HEIGHT);
    const params: any = {
      value,
      DMSansFont,
      DMSansBoldFont,
    };

    if(height_per_signatory + offset > 672){
      count = 0;
      height_per_signatory = 0;
      offset = 100;
      newpage = pdfDoc.addPage(PageSizes.Letter);
      qrcodeSection(newpage, QRCodeEmbed, DMSansFont);
    }

    params.index = key;
    params.offset = offset;
    params.height_per_signatory = height_per_signatory;
    params.page = newpage;

    signatory(params);
    count += 1;
  });

  return {
    newpage,
    height_per_signatory
  };
};

// RE-USABLE SIGNATORY DETAILS
const signatory = async (props: any) => {
  const {
    page,
    index,
    height_per_signatory,
    value,
    offset,
    DMSansFont,
    DMSansBoldFont,
  } = props;
  const { height } = page.getSize();
  const labels = getSignatoryLabels();

  if(index > 0){
    const line_y = height_per_signatory + offset - 21;

    page.drawRectangle({
      x: 37,
      height: 2,
      width: 519,
      y: height - line_y,
      color: headerColor,
    });
  }

  labels.forEach((item: any, key: number) => {
    const y = (key * 20) + offset + item.size + height_per_signatory;
    
    page.drawText(item.label1, {
      x: item.l1_x,
      y: height - y,
      size: item.size,
      font: DMSansFont,
      color: textColor,
    });
    page.drawText(getSinatoryAndApproverValue(item.label1, value), {
      x: item.l1_value_x,
      y: height - y,
      size: item.size,
      color: textColor,
      font: DMSansBoldFont,
    });
    if(item.label2){
      page.drawText(item.label2, {
        x: item.l2_x,
        y: height - y,
        size: item.size,
        font: DMSansFont,
        color: textColor,
      });
      page.drawText(getSinatoryAndApproverValue(item.label2 || " ", value), {
        x: item.l2_value_x,
        y: height - y,
        size: item.size,
        font: DMSansBoldFont,
        color: textColor,
      });
    }
  });
};

const qrcodeSection = (page: any, QRCodeEmbed: any, DMSansFont: any) => {
  page.drawText("This document was signed with SignSecure™", {
    x: 375,
    y: 44,
    size: 10,
    font: DMSansFont,
    color: rgb(0.4, 0.4, 0.4),
  });
  page.drawText("Scan the QR code to verify document", {
    x: 375,
    y: 31,
    size: 10,
    font: DMSansFont,
    color: rgb(0.4, 0.4, 0.4),
  });
  
  page.drawImage(QRCodeEmbed, {
    x: 25,
    y: 26,
    width: 60,
    height: 60,
  });
};

const QR_SIZE = 55;
const QR_PADDING_RIGHT = 15;

export const qrcodePosition = (
  position: string,
  canvas_width: number,
  canvas_height: number
) => {
  const id = nanoid();

  const qritem = {
    id,
    qrvalue: id,
    type: "qrcode_sign",
    x: 10,
    y: 10,
    width: QR_SIZE,
    height: QR_SIZE,
  };

  switch (position) {
  case "Top left":
    return qritem;
  case "Top right":
    qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  case "Bottom left":
    qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  case "Bottom right":
    qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
    qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  default:
    return qritem;
  }
};

export const dragPosition = (
  position: string,
  canvas_width: number,
  canvas_height: number
) => {
  const id = nanoid();

  const qritem = {
    id,
    qrvalue: id,
    type: "qrcode_sign",
    x: 10,
    y: 10,
    width: QR_SIZE,
    height: QR_SIZE,
  };

  switch (position) {
  case "Top left":
    return qritem;
  case "Top right":
    qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  case "Bottom left":
    qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  case "Bottom right":
    qritem.x = canvas_width - (QR_SIZE + QR_PADDING_RIGHT);
    qritem.y = canvas_height - (QR_SIZE + QR_PADDING_RIGHT);
    return qritem;
  default:
    return qritem;
  }
};

export const getViewport = (canvas: any, scale: number) => {
  if (_.isEmpty(canvas)) {
    return {
      CANVAS_WIDTH: 612 * scale,
      CANVAS_HEIGHT: 792 * scale,
    };
  }

  return {
    CANVAS_WIDTH: canvas.width * canvas.scale,
    CANVAS_HEIGHT: canvas.height * canvas.scale,
  };
};
