import React from "react";
import { auditTrailDescending } from "@src/utils/transform-helper";
import { SignatureContext } from "../provider/signature";
import Icon from "@expo/vector-icons/Feather";
import { View, Text } from "react-native";
import useStyles from "./styles.css";
import moment from "@src/utils/moment-timezone-helper";

const Parties = () => {
  const styles = useStyles();
  const { document } = React.useContext(SignatureContext);
  const auditTrail = React.useMemo(() => {
    return auditTrailDescending(document?.workflow?.auditTrail ?? []);
  },[document?.workflow?.auditTrail]);

  return (
    <>
      {auditTrail.map((item: any, index: number) => {
        const details = item.details === "Manifest Generated" ? item.details : 
          `${item.author.name} ${item.details.toLowerCase()} the document`;

        return (
          <View key={`parties_ids${index}`} style={[styles.partiesContainer]}>
            <View style={styles.witnessNumber}>
              <Icon name="check" size={20} color="white" />
            </View>
            <View style={styles.partiesContainer2}>
              <Text style={styles.name}>{moment(item.timestamp).format("MM/DD/YYYY HH:mm")}</Text>
              <View style={{flex: 1}}>
                <Text style={styles.role}>{details}</Text>
                {item.remarks && <Text style={styles.remarks}>Remarks:{" "}
                  <Text style={{fontFamily: "DMSans-Bold"}}>{item.remarks}</Text>
                </Text>}
              </View>
            </View>
          </View>
        );
      })}
    </>
  );
};

export default React.memo(Parties);
