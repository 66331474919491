import React from "react";
import { View } from "react-native";
import AuditTrail from "./audit-trail";
import DocumentDetails from "./document-details";
import Header from "./header";
import CancelDocument from "./modals/cancel-document";
import DeclineToSign from "./modals/decline-to-sign";
import QuickSigning from "./modals/quick-signing";
import Screens from "./screens";
import useStyles from "./styles.css";
import { SignatureProvider } from "./provider/signature";

function PublicView() {
  const styles = useStyles();

  return (
    <SignatureProvider>
      <View style={styles.index_container}>
        {/* COMMENTED OUT FOR STICKY HEADER */}
        <Header />
        <View style={styles.public_view_cointainer}>
          <Screens />
        </View>
        <CancelDocument />
        <DeclineToSign />
        <DocumentDetails />
        <AuditTrail />
        <QuickSigning />
      </View>
    </SignatureProvider>
  );
}

export default PublicView;
