import { base64ToBlob } from "base64-blob";
import _ from "lodash";

export const SignUpFormDataParser = async (obj: any) => {
  const formData = new FormData();

  formData.append("givenName", obj.givenName);
  formData.append("lastName", obj.lastName);
  formData.append("email", obj.email);
  formData.append("password", obj.password);
  formData.append("referralCode", obj.referralCode);
  formData.append("position", obj.position);

  return formData;
};

const getRole = (role: string) => {
  switch(role){
  case "Needs to sign":
    return "SIGN";
  case "Needs to approve":
    return "APPROVE";
  case "Receives a copy":
    return "COPY";
  }
};

export const CreateDocumentDataParser = async (signsecureInputs: any, ipInfo: any) => {
  const { files, foldername, shareable, pdfEncryptPassword } = signsecureInputs;

  const promises = files.map(async (value: any, key: number) => {
    let data = {} as any;
    const file = await base64ToBlob(value.uri);
    data["files"] = {blob: file, filename: `${value.name}.pdf`};
    data[`data[${key}][ip]`] = ipInfo;
    data[`data[${key}][isShareToTeam]`] = shareable || false;
    data[`data[${key}][password]`] = pdfEncryptPassword;
    // data[`data[${key}][country]`] = ipInfo.country;
    // data[`data[${key}][timezone]`] = ipInfo.timezone;
    // data[`data[${key}][regionCity]`] = `${ipInfo.regionName}/${ipInfo.city}`;
    if(value.isDraft){
      data[`data[${key}][id]`] = value.id;
      data[`data[${key}][batchName]`] = foldername;
    }
    data[`data[${key}][name]`] = value.name;
    data[`data[${key}][description]`] = value.description || "None";
    data[`data[${key}][privacyType]`] = value.privacyType || "CONFIDENTIAL";
    data[`data[${key}][workflow][signOrder]`] = value.signOrder || false;
    data[`data[${key}][workflow][emailContent][subject]`] = value.subject;
    data[`data[${key}][workflow][emailContent][message]`] = value.message || "";
    data[`data[${key}][workflow][qrPosition]`] = value.qrposition || "Top left";

    if(!_.isEmpty(value.reminders)){
      data[`data[${key}][workflow][reminders][expiration]`] = value.reminders.expiration;
      data[`data[${key}][workflow][reminders][reminderInXDays]`] = value.reminders.reminderInXDays;
      data[`data[${key}][workflow][reminders][reminderEveryXDays]`] = value.reminders.reminderEveryXDays;
      data[`data[${key}][workflow][reminders][reminderBeforeXDays]`] = value.reminders.reminderBeforeXDays;
    }

    const partiesPromises = value.parties.map(async (party: any, x: number) => {
      return {
        [`data[${key}][workflow][parties][${x}][id]`]: party.id,
        [`data[${key}][workflow][parties][${x}][trackId]`]: party.trackId,
        [`data[${key}][workflow][parties][${x}][name]`]: party.name,
        [`data[${key}][workflow][parties][${x}][email]`]: party.email,
        [`data[${key}][workflow][parties][${x}][role]`]: getRole(party.role),
        [`data[${key}][workflow][parties][${x}][designation]`]: party.designation || "",
      };
    });

    const partiesData = await Promise.all(partiesPromises);
    partiesData.forEach((item: any) => {
      data = _.merge(data, item);
    });

    const signaturesPromises = value.signatures.map(async (signature: any, x: number) => {
      const layerPromises = signature.layerFields.map(async (layer: any, y: number) => {
        const data = {} as any;
   
        switch(layer.type){
        case "signature":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][text]`] = layer.textConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontSize]`] = layer.textConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][width]`] = layer.width;
          break;
        case "signature-name":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][text]`] = layer.textConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontSize]`] = layer.textConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][x]`] = layer.nameConfig.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][y]`] = layer.nameConfig.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][text]`] = layer.nameConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontSize]`] = layer.nameConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fill]`] = "#000000";
          break;
        case "signature-date":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][text]`] = layer.textConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontSize]`] = layer.textConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][x]`] = layer.dateConfig.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][y]`] = layer.dateConfig.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][text]`] = layer.dateConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontSize]`] = layer.dateConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fill]`] = "#000000";
          break;
        case "signature-name-designation":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][text]`] = layer.textConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontSize]`] = layer.textConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][x]`] = layer.nameConfig.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][y]`] = layer.nameConfig.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][text]`] = layer.nameConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontSize]`] = layer.nameConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][x]`] = layer.designationConfig.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][y]`] = layer.designationConfig.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][text]`] = layer.designationConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][fontSize]`] = layer.designationConfig.fontSize;
          break;
        case "full-name":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][text]`] = layer.nameConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontSize]`] = layer.nameConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][nameConfig][fill]`] = "#000000";
          break;
        case "initials":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][text]`] = layer.textConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontSize]`] = layer.textConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][textConfig][width]`] = layer.width;
          break;
        case "designation":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][text]`] = layer.designationConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][designationConfig][fontSize]`] = layer.designationConfig.fontSize;
          break;
        case "date-time":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][text]`] = layer.dateConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontSize]`] = layer.dateConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fill]`] = "#000000";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][width]`] = layer.width;
          break;
        case "date":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][text]`] = layer.dateConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontSize]`] = layer.dateConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fontFamily]`] = "DMSans";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][fill]`] = "#000000";
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][dateConfig][width]`] = layer.width;
          break;
        case "textbox":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][text]`] = layer.inputConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][fontSize]`] = layer.inputConfig.fontSize;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][height]`] = layer.height;
          break;
        case "checkbox":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = layer.height || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = layer.rotation || 0;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = layer.fill;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][checkConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][checkConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][checkConfig][width]`] = layer.checkConfig.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][checkConfig][height]`] = layer.checkConfig.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][checkConfig][marginRight]`] = layer.checkConfig.marginRight;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][x]`] = layer.x;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][y]`] = layer.y;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][width]`] = layer.width;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][height]`] = layer.height;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][text]`] = layer.inputConfig.text;
          data[`data[${key}][workflow][signatures][${x}][${y}][inputConfig][fontSize]`] = layer.inputConfig.fontSize;
          break;
        case "qrcode_sign":
          data[`data[${key}][workflow][signatures][${x}][${y}][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][type]`] = layer.type;
          data[`data[${key}][workflow][signatures][${x}][${y}][trackId]`] = layer.trackId;
          data[`data[${key}][workflow][signatures][${x}][${y}][email]`] = layer.email;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][id]`] = layer.id;
          data[`data[${key}][workflow][signatures][${x}][${y}][config][x]`] = String(layer.x);
          data[`data[${key}][workflow][signatures][${x}][${y}][config][y]`] = String(layer.y);
          data[`data[${key}][workflow][signatures][${x}][${y}][config][fill]`] = "black";
          data[`data[${key}][workflow][signatures][${x}][${y}][config][stroke]`] = "black";
          data[`data[${key}][workflow][signatures][${x}][${y}][config][strokeWidth]`] = "1";
          data[`data[${key}][workflow][signatures][${x}][${y}][config][rotation]`] = "0";
          data[`data[${key}][workflow][signatures][${x}][${y}][config][width]`] = String(layer.width);
          data[`data[${key}][workflow][signatures][${x}][${y}][config][height]`] = String(layer.height);
          break;
        }
        return data;
      });

      const layersData = await Promise.all(layerPromises);
      return layersData;
    });

    const signaturesData = await Promise.all(signaturesPromises);
    signaturesData.forEach((signature: any) => {
      if(!_.isEmpty(signature)){
        signature.forEach((item: any) => {
          if(!_.isEmpty(item)){
            data = _.merge(data, item);
          }
        });
      }
    });

    return {
      [`data[${key}][id]`]: data,
    };
  });
  const resultArray = await Promise.all(promises);
  const formData = resultArray.reduce((accumulator, currentValue, index) => {
    _.forEach(currentValue[`data[${index}][id]`], (value: any, key: any) => {
      if(key === "files"){
        accumulator.append(key, value.blob, value.filename);
      }
      accumulator.append(key, value);
    });
    return accumulator;
  }, new FormData());

  if(files.length > 1){
    formData.append("batchName", foldername || "Folder 1");
  }

  return formData;
};

export const ImportDocumentDataParser = async (signsecureInput: any, ipInfo: any) => {

  const formData = new FormData();

  const { data, files } = signsecureInput;
  
  console.log(signsecureInput);

  const generatedFiles: any = [];

  const file: any = {};
  //   blob: await files[0].content,
  //   filename: files[0].name
  // }

  // formData.append("files", file.blob, file.filename)

  // console.log('Formdata ', formData)
  const promises = files.map(async (x: any) => {
    // const file = await base64ToBlob(await x.content

    file.blob = await x.content,
    file.filename = x.name;
    
    formData.append("files", file.blob, file.filename);
    
  });

  formData.append("batchName", "Restore");
  formData.append("user",  await data);

  return formData;
};


export const EncryptPDFDocumentDataParser = async (signsecureInput: any) => {

  const formData = new FormData();
  const { pdfEncryptPassword, files } = signsecureInput;
   files.map(async (value: any, key: number) => {
    formData.append("file", await base64ToBlob(value.uri), `${value.name}.pdf`);
    formData.append("password",  pdfEncryptPassword);
  });
  console.log("EncryptPDFDocumentDataParser",formData);
  return formData;
}