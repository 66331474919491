import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const AddInfo = (props: any) => {
  const styles = useStyles();

  return (
    <>
    <View style={styles.titleAlignment}>
      <Text style={styles.textTitle}>Password Encryption</Text>      
    </View>
        <Text style={styles.t_subtitle}> 
            Create a strong and unique password below: at least {"\n"}
            8 characters long, with a mix of uppercase and lowercase {"\n"} 
            letters, numbers, and special characters.Remember to {"\n"}
            store it securely for future document access.
        </Text>

    </>

  );
};

export default AddInfo;
