import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";

const useStyles = CreateResponsiveStyle(
  {
    errorColor:{
      color: "red",
      marginTop:10
    },
    container: {
      flex:1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG3,
      height: "100%",
    },
    titleAlignment: {
      alignItems:"center", alignContent:"center", justifyContent: "center", alignSelf: "center"
    },
    btn_alignment:{ 
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 43,
      paddingBottom: 53
    },
    btn_cancel:{
      width: 190,
      height: 47, 
      paddingHorizontal: 0,
      backgroundColor: 
      colors.transparent, 
      borderWidth: 1, 
      borderColor: colors.colorPrimary,
    },
    panel_container: {
      width: 250,
      // backgroundColor: "red",
    },
    generatedPasasword: {
      display: "none"
    },
    share_docs_wrapper: { flexDirection: "row", alignItems: "flex-start", paddingTop: 38 },
    share_docs_box: {
      width: 18, height: 18, borderWidth: 1,
      borderColor: colors.black, marginRight: 15
    },
    t_share_document: {...TypographyStyle.Body2Regular, color: colors.black3, paddingLeft: 10},
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    btn_proceed:{
      width: 190, 
      height: 47, 
      paddingHorizontal: 0,
      backgroundColor: colors.colorPrimary },
       
    label_proceed: { fontFamily: "DMSans-Bold" },
    v_width: { width: 10},
    t_title: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 35,
      lineHeight: 28, color: colors.colorPrimary, textAlign: "center" },
    width100: {
      width:"100%"
    },
      v_inputs: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 4,
        width: "100%"
      },
      email: { width: "100%", maxWidth: "100%"},
    // UPDATE TEAM NAME
    teamname_modal: {
      width: 534,
      height: 326,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    fontWeight: { fontWeight: "700" },
    inputStyle: { fontSize: 16, lineHeight: 24 },
    ti_name: { width: "100%", maxWidth: "100%", marginTop: 25},
    mobile_btns: {},

    // EMAIL-SENT
    email_sent_modal: {
      width: 525,
      // height: 670,
      borderRadius: 8,
      paddingLeft: 62,
      paddingRight: 62,
      alignItems: "flex-start",
      backgroundColor: colors.white,
    },
    textTitle:{
      fontFamily: "DMSans", fontSize: 24, marginTop: 53, fontWeight: "700",
      lineHeight: 28, color: colors.black3, textAlign: "center", alignItems: "center"
    },
    t_subtitle: {fontFamily: "DMSans", fontSize: 14, marginTop: 25, fontWeight: "400",
      lineHeight: 18, color: colors.darkGray, textAlign: "left"  },
    text_subt: {fontFamily: "DMSans", fontSize: 18, marginTop: 22, fontWeight: "400",
      lineHeight: 24, color: colors.darkGray, textAlign: "center"  },

    btn_close: { position: "absolute", top: 25, right: 25},

    // REMOVE ITEM
    delete_modal: {
      width: 550,
      height: 320,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    divider: { width: 22 },
    delete_btn_cancel: { width: 156, height: 47, marginTop: 46, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.colorPrimary },
    delete_label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    delete_btn_proceed: { width: 156, height: 47, marginTop: 46, 
      paddingHorizontal: 0, backgroundColor: colors.colorPrimary },
    delete_label_proceed: { fontFamily: "DMSans-Bold" },
    delete_btn_backtomyteam:{
      height: 47,
      width: "41%", 
      marginTop: 40,
      paddingHorizontal: 0,
      backgroundColor: colors.colorPrimary },

  },
  {
    [DEVICE_SIZES.LG]: {
      t_title: { fontSize: 18, lineHeight: 22.5, marginTop: 25, },
      t_subtitle: { fontSize: 14, lineHeight: 16, marginTop:8, },
      label_proceed: { fontSize: 12 },

      // UPDATE TEAM NAME
      teamname_modal: { width: 492, height: 290, borderRadius: 8 },
      inputStyle: { fontSize: 12, lineHeight: 18 },

      // EMAIL SENT
      btn_close: { position: "absolute", top: 15, right: 15},
      // DELETE MEMBER
      delete_modal: { width: 492, height: 385, paddingHorizontal: 40 },
      delete_btn_proceed: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      delete_btn_cancel: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      delete_label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      delete_label_cancel: { fontSize: 10.5, lineHeight: 10.5 },
      divider: { width: 17 },

    },
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      teamname_modal: {
        width: "85%",
        height: 250,
      },
      mobile_btns: { 
        width: 115,
        marginBottom: 30,
        marginTop: 20
      }
    },
  }
);

export default useStyles;
