import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { View } from "react-native";
import Background from "./background";
import Logo from "./logo";
import LogoTop from "./logo-top";
import SignUpProvider from "./provider";
import Screens from "./screens";
import useStyles from "./styles.css";

const SignIn = () => {
  const styles = useStyles();
  const minTablet = tabletSize();

  return (
    <SignUpProvider>
      <View style={styles.container}>
        <Background />
        <View style={styles.v_body}>
          {minTablet ? 
            <LogoTop 
              content={`Experience fraud-free security and efficiency\nwith our e-signature system.`}
              /> : 
            <Logo 
              title={`Ready to create\nan account?`}
              subtitle={`Experience fraud-free security and\nefficiency with our e-signature setup.`}
              /> }
          <Screens />
        </View>
      </View>
    </SignUpProvider>
  );
};

export default SignIn;
