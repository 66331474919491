import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  signupActions,
  selectedSigningIn,
  selectedSigningData,
  selectedSigningFailed,
} from "../slices/signup.slice";

// Types
import { SignupInput, ErrorValue, SignupWithPromoValue } from "../types";

type Operators = {
  data: any;
  failed: ErrorValue;
  isLoading: boolean;
  onSignup: (params: SignupInput) => void;
  onSignupWithPromo: (params: SignupWithPromoValue) => void;
};

export const useSignupService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    data: useAppSelector(selectedSigningData),
    failed: useAppSelector(selectedSigningFailed),
    isLoading: useAppSelector(selectedSigningIn),
    onSignup: useCallback((params: SignupInput) => {
      dispatch(signupActions.signupRequest(params));
    }, [dispatch]),
    onSignupWithPromo: useCallback((params) => {
        dispatch(signupActions.signupPromoRequest(params));
    },[dispatch]),
  };
};

export default useSignupService;
