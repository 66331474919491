import { SignupInput } from "@src/ducks/types";
import { useInputHelper } from "@src/utils/inputs-helper";
import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

interface ContextValue {
  state: SignupInput;
  activeIndex: number;
  subscriptionPlan: any;
  onDispatch: (value: string) => (value: string) => void;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setSubscriptionPlan: Dispatch<SetStateAction<any>>;
}

const initialInputState = {
  givenName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
  "user-id": "",
  promoCode: "",
} as SignupInput;

const SignInContext = createContext<ContextValue>({} as ContextValue);

interface SignInProviderProps {
  children: React.ReactElement;
}

const SignUpProvider = ({ children }: SignInProviderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>({});
  const { state, onDispatch } = useInputHelper(initialInputState);

  const value = {
    state, onDispatch,
    activeIndex, setActiveIndex,
    subscriptionPlan, setSubscriptionPlan
  };

  return (
    <SignInContext.Provider value={value}>{children}</SignInContext.Provider>
  );
};

export const useSignupContext = () => {
  return useContext(SignInContext);
}

export default SignUpProvider;

