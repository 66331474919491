import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Linking, Text } from "react-native";
import useStyles from "./styles.css";

const CopyRightContent = ({ color, termsStyle }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  const [ currentYear , setCurrentYear ] = useState("");

  useEffect (() => {
    const year = new Date().getFullYear();
    setCurrentYear(year + "");
  }, []);
  

  return (
    <Text style={[styles.copyRightStyle, termsStyle, { color }]}>
      <Text
        style={styles.copyRightBtnStyle}
        onPress={() => navigation.navigate("terms-condition")}
      >
        Terms & Conditions
      </Text>{"  "}
      |
      <Text
        style={styles.copyRightBtnStyle}
        onPress={() => navigation.navigate("privacy-policy")}
      >
        {"  "}
        Privacy Policy
      </Text>
      {"\n"}
      { currentYear + " " }
      <Text style={styles.copyRightBtnStyle} 
        onPress={() => Linking.openURL("https://www.unawa.asia")}>
          UNAWA
      </Text>. All rights reserved
    </Text>
  );
};

export default CopyRightContent;
