import React from "react";
import { Modal, Text, View, Pressable, useWindowDimensions } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";
import { TextInput02,TextInput03 } from "@src/components/textinput";
import Icon from "@expo/vector-icons/MaterialIcons";
import colors from "@assets/colors";
import ActionsMenu, {MeasureValue} from "./actions-menu";
import { Picker } from '@react-native-picker/picker'


const Fields = (props: any) => {
  const styles = useStyles();
  const { showShareDocument, setShowShareDocument, onActionButton  } = React.useContext(SignatureContext);
  const [isFocus, setFocus] = React.useState<boolean>(false);
  const [measure, setMesasure] = React.useState<MeasureValue>({} as MeasureValue);
  const actionRef = React.useRef<any>(null);
  const { width } = useWindowDimensions();

  const onProceed = () => {
    setShowShareDocument(false);
  };
  
  React.useEffect(() => {
    if (actionRef.current) {
      actionRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            width,
            height,
          });
        }
      );
    }
  }, [actionRef.current, isFocus, width]);


  return (
    <>
    <View>
      <TextInput02 
        label="Email"
        style={styles.emailStyle}
        placeholder="jaundelacruz@email.com"
      />
    </View>
    <View style={{flexDirection: "row",justifyContent: "center", top: 22}}>
        <Text style={styles.nameShareDocument}>
            Juan Dela Cruz
        </Text>
        <Text style={styles.emailShareDocument}>
            juandelacruz@email.com
        </Text>
        <Pressable
          onPress={() => setFocus(true)}
          style={styles.btn_other}>
          <Text selectable={false} style={styles.btn_label_other}>Viewer</Text>
          <Icon name="arrow-drop-down" size={18} color={colors.blue} />
        </Pressable>
       <ActionsMenu 
          visible={isFocus}
          onSelected={onActionButton}
          onClose={() => setFocus(false)}
          measures={measure}
       />
      {/* <Picker
        // selectedValue={selectedLanguage}
        // onValueChange={(itemValue, itemIndex) =>
        //   setSelectedLanguage(itemValue)
        // }
        mode="dropdown"
        style={{borderWidth: 0, backgroundColor: "white"}}
        >
        <Picker.Item label="Viewer"/>
        <Picker.Item label="Remove Access"/>
      </Picker> */}
    </View>
    </>
  );
};

export default Fields;
