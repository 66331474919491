import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.blurBG,
    },
    v_modal: {
      width: 520,
      height: 559,
      borderRadius: 10,
      paddingLeft: 50,
      paddingRight: 50,
      alignItems: "flex-start",
      justifyContent: "flex-start",
      backgroundColor: colors.white,
    },
    t_message: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 35,
      lineHeight: 28, color: colors.colorPrimary, textAlign: "center",},
    btn_cancel: { width: 156, height: 47, marginTop: 46, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.colorPrimary },
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    btn_proceed: { width: 156, height: 47, marginTop: 46, 
      paddingHorizontal: 0, backgroundColor: colors.colorPrimary },
    label_proceed: { fontFamily: "DMSans-Bold" },
    divider: { width: 22 },

    // DECLINE TO SIGN
    v_modal_decline: {
      width: 776,
      height: 453,
      borderRadius: 8,
      alignItems: "center",
      paddingHorizontal: 50,
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_row_label: {width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"},
    t_declinetosign: { fontFamily: "DMSans-Bold", fontSize: 24, 
      lineHeight: 28, color: colors.colorPrimary},
    ti_message: {fontSize: 18, lineHeight: 24, color: colors.darkGray},
    ti_borderStyle: {width: 676, height: 193, marginTop: 15},
    v_row_buttons: {flexDirection: "row", alignItems: "center"},
    decline_btn_width: { width: 328 },


    // Share Document
    introMargin: {
      paddingTop: 40
    },
    introText: {
      fontFamily: "DMSans-bold",
      fontSize: 24,
      lineHeight: 28,
      color: colors.black3,
      textAlign: "center"
    },
    introText2: {
      fontFamily: "DMSans-bold",
      fontSize: 18,
      lineHeight: 18,
      color: colors.black3,
      textAlign: "left",
      paddingTop: 47
    },

    //input Style
    emailStyle: { width: 420},

    nameShareDocument: { 
      fontFamily: "DMSans",
      fontWeight: "400",
      lineHeight: 16,
      fontSize: 16,
      color: colors.black3,
      textAlign:"left",
    },
    emailShareDocument: {
      color: colors.grayPrimary,
      fontFamily: "DMSans",
      fontWeight: "400",
      lineHeight: 16,
      fontSize: 12,
      left: "5%",
    }, 
    item_separator: { height: 8 },
    dropdown: {
      height: 10,
      borderWidth: 0.5,
      position: "absolute",
      backgroundColor: "white",
      justifyContent: "center",
      borderColor: colors.white,
      left: "30%"
    },
    btn_other: {
      height: 18,
      width: 50,
      left: 100,
      flexDirection: "row", 
      alignItems: "center",
      justifyContent: "center",
    },
    btn_label_other: {
      fontSize: 16,
      lineHeight: 16,
      marginRight: 6,
      color: colors.black,
      fontFamily: "DMSans-Bold",
    },

    //checkbox

    share_docs_wrapper: { flexDirection: "row", alignItems: "center", top: "15%" },
    share_docs_box: {
      width: 18, height: 18, borderWidth: 1,
      borderColor: colors.black, marginRight: 15
    },
    t_share_document: {
      fontSize: 16,
      fontFamily: 'DMSans-Bold'
    },
    copyLinkText: {
      color: colors.blue,
      fontFamily: 'DMSans',
      fontSize: 15,
      fontWeight: "400",
      justifyContent: "center",
      left: 5
    },

    modal: { flex: 1 },
    flatlist: { marginTop: 18},
    //btn
    btn_view: {
      flexDirection: "row", 
      alignItems: "center", 
      alignSelf:"center", 
      top: "25%"
    },
    btn_cancel_share: { 
      width: 156, 
      height: 47, 
      paddingHorizontal: 0,
      backgroundColor: colors.transparent, 
      borderWidth: 1, borderColor: 
      colors.colorPrimary 
    },
    btn_proceed_share: { 
      width: 156, 
      height: 47, 
      paddingHorizontal: 0, 
      backgroundColor: colors.colorPrimary
    },
    label_cancel_share: { 
        fontFamily: "DMSans-Bold", 
        color: colors.colorPrimary 
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_modal: { width: 492, height: 385, paddingHorizontal: 40 },
      t_message: { fontSize: 18, lineHeight: 22.5, marginTop: 25, },
      btn_proceed: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      btn_cancel: { width: 145, height: 35, marginTop: 40, borderRadius: 6 },
      label_proceed: { fontSize: 10.5, lineHeight: 10.5 },
      label_cancel: { fontSize: 10.5, lineHeight: 10.5 },
      divider: { width: 17 },

      v_modal_decline: { width: 700, height: 413, paddingHorizontal: 45 },
      t_declinetosign: { fontSize: 18, lineHeight: 22.5 },
      ti_message: {fontSize: 15, lineHeight: 20},
      ti_borderStyle: {width: 610, height: 180, marginTop: 12},
      decline_btn_width: { width: 297 },

    },
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {
      v_row_buttons: {flexDirection: "column", alignItems: "center", paddingTop: 50},
      v_modal_decline: {
        width: 381,
        height: 600,
        borderRadius: 8,
        alignItems: "center",
        paddingHorizontal: 20,
        justifyContent: "center",
        backgroundColor: colors.white,
      },
      v_row_label: {
        paddingTop: 0
      },  
      v_modal: {
        width: "95%",
      },
      decline_btn_width: { width: 331 },
      btn_proceed: { width: 145, height: 45, marginTop: 20, borderRadius: 6 },
      btn_cancel: { width: 145, height: 45, marginTop: 20, borderRadius: 6 },
      ti_borderStyle: {width: 331, height: 224, marginTop: 15},
      ti_message: {fontSize: 16, lineHeight: 24, color: colors.darkGray},
      t_declinetosign : {
          color: colors.black3
      },
    },
  }
);

export default useStyles;
