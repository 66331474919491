import AffixIcon from "@assets/svg/affix-signature";
import Button from "@src/components/button/Button01";
import { useSignsecureService } from "@src/ducks/hooks";
import React from "react";
import { Modal, Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";
import Intro from "./intro";
import Fields from "./fields";
import Checkbox from "./checkbox";

const ShareDocument = (props: any) => {
  const styles = useStyles();
  const { showShareDocument, setShowShareDocument  } = React.useContext(SignatureContext);

  const onProceed = () => {
    setShowShareDocument(false);
  };

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={() => setShowShareDocument(false)}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <View style={styles.v_modal} >
          <Intro />
          <Fields />
          <Checkbox />

          <View style={styles.btn_view}>
            <Button
              label="Cancel"
              style={styles.btn_cancel_share}
              labelStyle={styles.label_cancel_share}
              onPress={() => setShowShareDocument(false)}
            />
            <View style={styles.divider} />
            <Button
              label="Share"
              style={styles.btn_proceed_share}
              labelStyle={styles.label_proceed}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ShareDocument;
