import Loading from "@src/components/loading";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";
import React, { useContext } from "react";
import { DrawerContext } from "../../drawer-provider";
import DeleteAccount from "@src/screens/account/delete-account";

// SCREENS
const Dashboard = React.lazy(() => import("screens/dashboard"));
const Inbox = React.lazy(() => import("screens/inbox"));
const Documents = React.lazy(() => import("screens/documents"));
const Account = React.lazy(() => import("screens/account"));
const Faq = React.lazy(() => import("@src/screens/faq"));
const Support = React.lazy(() => import("screens/support"));
const MyTeam = React.lazy(() => import("screens/my-teams"));
const Signatures = React.lazy(() => import("@src/screens/signatures"));
const CreateDocument = React.lazy(() => import("screens/create-document"));
const DocumentSent = React.lazy(() => import("screens/create-document/document-sent"));
const DocumentDraft = React.lazy(() => import("screens/create-document/document-draft"));
const AffixDocument = React.lazy(() => import("screens/affix-document"));
const AdvanceSettings = React.lazy(()=> import("screens/account/advance-settings"));
const UpgradePlan = React.lazy(()=> import("screens/account/upgrade-plan"));
const PaymentResultPage = React.lazy(()=> import("screens/account/upgrade-plan/payment-result-page"));
const FaceRecognition = React.lazy(()=> import("screens/account/face-id-register"));
const DocumentDeclined = React.lazy(()=> import("screens/affix-document/document-response/document-declined"));
const DocumentCancelled = React.lazy(()=> import("screens/affix-document/document-response/document-cancelled"));

const GuestDashboard = React.lazy(()=> import("screens/guest-screen"));
const CancelPlan = React.lazy(()=> import("screens/account/cancel-plan"));


const DrawerItemBody = () => {
  const { activeTab } = useContext(DrawerContext);
  const isGuest = useAppSelector(selectedAuthGuestRole);

  const renderContent = React.useMemo(() => {
    switch (activeTab) {
    case "Dashboard":
      return isGuest ? <GuestDashboard /> : <Dashboard />;
    case "Signatures":
      return <Signatures />;
    case "Manage Users":
      return <MyTeam />;
    case "My Account":
      return <Account />;
    case "FAQs":
      return <Faq />;
    case "Support":
      return <Support />;
    case "Inbox":
      return <Inbox />;
    case "Documents":
      return <Documents />;
    case "Create Document":
      return <CreateDocument />;
    case "Affix Document":
      return <AffixDocument />;
    case "Document Sent":
      return <DocumentSent />;
    case "Document Saved":
      return <DocumentDraft />;
    case "Advance Settings":
      return <AdvanceSettings />;
    case "Face Recognition":
      return <FaceRecognition />;
    case "Cancel Plan":
      return <CancelPlan />;
    case "Upgrade Plan":
    case "Get Free Trial":
      return <UpgradePlan />;
    case "Payment Success":
      return <PaymentResultPage status="payment-success" />;
    case "Payment Failed":
      return <PaymentResultPage status="payment-failed" />;
    case "Document Declined":
      return <DocumentDeclined />;
    case "Document Cancelled":
      return <DocumentCancelled />;
    case "Delete Account":
      return <DeleteAccount />;
    default:
      return isGuest ? <GuestDashboard /> : <Dashboard />;
    }
  }, [activeTab, isGuest]);

  return (
    <React.Suspense fallback={<Loading />}>{renderContent}</React.Suspense>
  );
};

export default DrawerItemBody;
