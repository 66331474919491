import moment from "moment";

const useMoment = () => {
    const getTimeZone = (): string => {
        const timezoneOffset = new Date().getTimezoneOffset()
        const offset = Math.abs(timezoneOffset)
        const offsetOperator = timezoneOffset < 0 ? '+' : '-'
        const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0')
        const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0')
      
        return `${offsetOperator}${offsetHours}:${offsetMinutes}`
    }

    const processMoment = (inp?: moment.MomentInput, strict?: boolean): moment.Moment => {
        // GET TIMEZONE OFFSET FOR MANILA
        // const offsetInHoursAsiaManila = +8
        return moment(inp, strict).utcOffset(getTimeZone())
    }

    return {
        moment: (inp?: moment.MomentInput, 
                strict?: boolean): moment.Moment => processMoment(inp, strict)
    }
}

export {
    useMoment
}

export default useMoment().moment