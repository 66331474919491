import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { Dimensions } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
const windowWidth = Dimensions.get("window").width;

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1, flexDirection: "row" },
    scrollView: { 
      flex: 1, 
      height: "100%", 
      maxHeight: 1080,
      paddingHorizontal: 70,
    },
    linear_style: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    drawerContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 2,
      width: 0,
      backgroundColor: colors.transparent,
    },
    guest_drawer: { position: "absolute"},
    contentButton: {
      marginLeft: 20,
      width: 50, 
      height: 50, 
      flexDirection: "row",
      alignItems:"center", 
      alignContent: "center", 
      justifyContent: "center"
    },
    contentButtonFocused: {
      marginLeft: 20,
      width: 200, 
      height: 50, 
      flexDirection: "row",
      alignItems:"center", 
      alignContent: "center", 
      justifyContent: "center"
    },
    chatButton: {
      alignItems: "flex-end",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderBottomLeftRadius: 20,
      backgroundColor: "#4E70BB"
    },
    drawer_mask: {
      width: "100%", height: 1080, 
      backgroundColor: colors.blurBG, 
      zIndex: 2, position: "absolute"
    },
    signSecureSupport:{
      ...TypographyStyle.heading1Bold,
    },
    chatSupportBtn : {
      position: "absolute", 
      right: 10, 
      bottom: 20
    },
    chatContainer: {
      borderWidth: 0.5,
      backgroundColor: "#ffffff",
      borderColor: colors.grayPrimary,
      width: windowWidth / 4,
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    tabletContainerLandscape: {
      width: windowWidth / 2.5,
    },
    tabletContainer: {
      width: windowWidth / 2,
      borderColor: colors.white
    },

    v_guest_view:{
      marginLeft: 380,
    }
  },
  {
    [DEVICE_SIZES.LG]: {
      scrollView: { paddingHorizontal: 52, maxHeight: 820 },
      v_guest_view:{
        marginLeft: 290,
      }
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      scrollView: { paddingHorizontal: 0 },
      container : {flexDirection: "column"},
      guest_drawer: { position: "relative", width: "100%"},
      chatSupportBtn : {
        position: "absolute", 
        right: 0, 
        bottom: 0
      },
      v_guest_view:{
        marginLeft: 0,
      }
    },
  }
);

export default useStyles;
