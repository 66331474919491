import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties, selectedEnableSignature, selectedSignatureNotes } from "@src/ducks/slices/signsecure.slice";
import { countSignatures } from "@src/utils/validation-helper";
import { subtract } from "lodash";
import React from "react";
import { View, Text } from "react-native";
import { SignatureContext } from "../provider/signature";
import useStyles from "./styles.css";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";

function CountSignatureMobile() {
  const styles = useStyles();
  const isMobileSize = mobileSize();
  const isTabletSize = tabletSize();
  const notes = useAppSelector(selectedSignatureNotes);
  const { document } = React.useContext(SignatureContext);
  const userParty = useAppSelector(selectedDocumentParties);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const signature = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);
  

  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document.workflow.status)
    || ["SIGNED", "APPROVED"].includes(userParty.status)){
    return null;
  }

  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document.workflow.status)
    || ["SIGNED", "APPROVED"].includes(userParty.status)){
    return null;
  }

  return (isMobileSize || isTabletSize) ? (
    <>
      <View style={styles.header_container_mobile}>
        <View>
          <Text style= {styles.header_text_mobile}>{notes}</Text>          
          {(["SIGN", "APPROVE"].includes(userParty.role) && enableSignature) && 
            <Text style= {styles.header_text_mobile}>
              {subtract(signature.total, signature.count)} of {signature.total} required fields completed.
            </Text>
          }
        </View>
      </View>
    </>
  ) : null;
}

export default CountSignatureMobile;
