import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { View } from "react-native";
import Background from "../background";
import Logo from "../logo";
import LogoTop from "../logo-top";
import useStyles from "../styles.css";
import RightContent from "./right-content";

const PaymentResultPage = () => {
  const styles = useStyles();
  const minTablet = tabletSize();

  return (
    <View style={styles.container}>
      <Background />
      <View style={styles.v_body}>
        {minTablet ?
        <LogoTop content={`Experience fraud-free security and efficiency\nwith our e-signature system.`} /> 
        : 
        <Logo title={`Experience fraud-free security and efficiency with our e-signature system.`} /> }
        <RightContent />
      </View>
    </View>
  );
};

export default PaymentResultPage;
