import Button from "@src/components/button/Button01";
import React from "react";
import { Modal, Text, View } from "react-native";
import useStyles from "./styles.css";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onProceed: () => void;
}

const FreeTrial30Days = (props: Props) => {
  const styles = useStyles();

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onCancel}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <View style={[ styles.v_modal ]} >
          <Text style={styles.t_title}>30-Day Free Trial Activated</Text>
          <Text style={styles.t_message}>We will deduct ₱1 from your credit card to verify that it is valid. This is a temporary charge, and the amount will be refunded after 30 days. Your free trial remains unaffected, and no additional charges will be made during this period.</Text>
          <View style={{flexDirection: "row", alignItems: "center"}}>
            <Button
              label="Cancel"
              style={styles.btn_cancel}
              labelStyle={styles.label_cancel}
              onPress={props.onCancel}
            />
            <View style={styles.divider} />
            <Button
              label="Continue"
              onPress={props.onProceed}
              style={styles.btn_proceed}
              labelStyle={styles.label_proceed}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default FreeTrial30Days;
