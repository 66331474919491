import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedDocumentParties, selectedEnableSignature, selectedSignatureNotes } from "@src/ducks/slices/signsecure.slice";
import { countSignatures } from "@src/utils/validation-helper";
import { mobileSize, tabletSize } from "@src/utils/screensize-helper";
import { subtract } from "lodash";
import React from "react";
import { Text, View } from "react-native";
import { SignatureContext } from "../provider/signature";
import CountSignatureMobile from './count-signature-mobile'
import useStyles from "./styles.css";

const CountSignature = () => {
  const styles = useStyles();
  const isMobileSize = mobileSize();
  const isTabletSize = tabletSize();
  const notes = useAppSelector(selectedSignatureNotes);
  const { document } = React.useContext(SignatureContext);
  const userParty = useAppSelector(selectedDocumentParties);
  const enableSignature = useAppSelector(selectedEnableSignature);
  const signature = React.useMemo(() => countSignatures(document, userParty), [document, userParty]);

  const includesValues = (values: any) => {
    if (notes) {
      const notesValue = notes.toLowerCase()
      const includesAllValues = values.filter((value: string) => notesValue.includes(value.toLowerCase()))
      return includesAllValues.length === values.length
    }
    return false
  }

  if(["DECLINED", "REJECTED", "CANCELLED", "COMPLETED"].includes(document.workflow.status)
    || ["SIGNED", "APPROVED"].includes(userParty.status)){
    return null;
  }

  return (isMobileSize || isTabletSize) ? (
    <CountSignatureMobile />
  ) : (
    <View style={styles.v_set_signature}>
      <Text style={includesValues(["WAITING", "SIGN"]) ? styles.t_set_signature_red : styles.t_set_signature}>
        {notes}
      </Text>
      {(["SIGN", "APPROVE"].includes(userParty.role) && enableSignature) && 
      <Text style={styles.t_set_signature} >{subtract(signature.total, signature.count)} of {signature.total} required fields completed</Text>}
    </View>
  );
};

export default React.memo(CountSignature);
