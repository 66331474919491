import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    header_background: {
      backgroundColor: colors.white,
      marginBottom: 5,
    },
    header_container: {
      height: 109,
      paddingTop: 12,
      alignItems: "center",
      justifyContent: "flex-end",
      borderBottomWidth: 0.5,
      flexDirection: "row",
      borderBottomColor: colors.border6,
    },
    header_document: {
      height: 115,
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      backgroundColor: colors.blue
    },
    header_document_padding: {
      paddingHorizontal: 60
    },
    linearStyle: { position: "absolute", width: "100%", height: "100%"},
    headerGuest: {
      flexDirection: "row", justifyContent:"space-between",
      borderBottomWidth: 0.5,
      height: 109,
      borderBottomColor: colors.border6,  
    },
    hideHeader:{
      display: "none"
    },
    drawer_item_header: {
      display:"none",
    },
    guestDisplay: {
      display: "none"
    },
    v_set_signature: {flexDirection: "row", marginTop: 20, alignItems: "center", justifyContent: "space-between"},
    t_set_signature: {
      fontFamily: "DMSans",
      fontWeight: "500",
      color: colors.black3,
      fontSize: 14,
      lineHeight: 17
    },
    t_set_signature_red: {
      fontFamily: "DMSans",
      fontWeight: "500",
      color: colors.red1,
      fontSize: 14,
      lineHeight: 17
    },
    v_height: { height: 40 },
    logoStyle: { paddingLeft: 15 },
    header_container_mobile:{
      width: "100%", 
      height: 65,
      backgroundColor: colors.black3,
      opacity: .8,
      justifyContent: 'center',
    },
    header_text_mobile:{
      fontSize: 14,
      fontFamily: "DMSans",
      color: colors.white1,
      // flexDirection: "row",
      // justifyContent: "space-between",
      paddingLeft: 21,
      // paddingRight: 44,
    },
    header_text_mobile_row:{
      fontSize: 14,
      fontFamily: "DMSans",
      color: colors.white1,
      // flexDirection: "row",
      // justifyContent: "space-between",
      paddingLeft: 21,
      // paddingRight: 44,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      header_container: { height: 81.75, paddingTop: 9 },
      header_document: { height: 81.75 },
      header_document_padding: {
        paddingHorizontal: 52
      },
      t_set_signature: { fontSize: 11, lineHeight: 13 },
      v_height: { height: 30.75 },
      logoStyle: { paddingLeft: 40 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      drawer_item_header: {
        display:"flex",
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "flex-end",
        backgroundColor: colors.colorPrimary,
      },
      header_container: {
        height: 60,
        paddingTop: 0,
        paddingLeft: 15,
        paddingRight: 15,
        justifyContent: "space-between",
      },
      header_document: {
        height: 60,
        justifyContent: "space-between",
      },
      header_document_padding: {
        paddingHorizontal: 20
      },
      v_height: { height: 20 },
    },
  }
);

export default useStyles;
