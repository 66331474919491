import { useAuthContext } from "@src/AuthProvider";
import { TextInput02 } from "@src/components/textinput";
import { useFetchPost } from "@src/utils/api/hooks";
import _ from "lodash";
import _debounce from 'lodash/debounce';
import numeral from "numeral";
import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { useSignupContext } from "../provider";
import MayaCard from "./maya-card";
import useStyles from "./styles.css";

const MakePayment = () => {
  const styles = useStyles();
  const { onErrorMessage } = useAuthContext();
  const { data, error, loading, runRequest } = useFetchPost();
  const { state, subscriptionPlan, onDispatch } = useSignupContext();

  const getAmountCost = React.useMemo(() => {
    const totalCost = numeral(subscriptionPlan.cost).format("0,000");
    return `₱${totalCost}`
  },[subscriptionPlan]);

  const totalAmount = React.useMemo(() => {
    if(data?.type === "TRIAL_DAYS"){
      return `Php ${numeral(1).format("0,000.00")}`;
    }else if(data?.type === "PERCENTAGE_DISCOUNT"){
      const cost = subscriptionPlan.cost;
      const total = cost - (cost * data?.percentageDiscount);
      return `Php ${numeral(total).format("0,000.00")}`;
    }else if(data?.type === "FIXED_DISCOUNT"){
      const cost = subscriptionPlan.cost;
      const total = cost - data?.fixedDiscountAmount;
      return `Php ${numeral(total).format("0,000.00")}`;
    }else{
      return `Php ${numeral(subscriptionPlan.cost).format("0,000.00")}`
    }
  },[data]);

  const debouncedCheckPromocode = (value: string) => {
    runRequest("/promo-code/verify",{
      "code": value,
      "email": state.email
    });
  }

  const handleDebouncedSearch = React.useCallback(_debounce(debouncedCheckPromocode, 1000), []);

  React.useEffect(() => {
    if(state.promoCode){
      handleDebouncedSearch(state.promoCode);
    }
  },[state.promoCode]);

  React.useEffect(() => {
    if(!_.isEmpty(error?.message)){
      onErrorMessage(error?.message);
    }
  },[error?.message]);

  return (
    <View style={styles.os_container}>
      <Text style={styles.t_order_summary}>Payment Summary</Text>
      <View style={styles.v_divider} />
      <View style={[styles.v_item_rows]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Email</Text>
        <Text style={styles.t_document_title}>{state.email}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Plan</Text>
        <Text style={styles.t_document_title}>{subscriptionPlan.name}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Billing</Text>
        <Text style={styles.t_document_title}>{subscriptionPlan.paymentMode}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Amount</Text>
        <Text style={styles.t_document_title}>{getAmountCost}</Text>
      </View>
      <View style={[styles.v_item_rows, styles.add_row_margin_top2]}>
        <Text style={[styles.t_document_title, styles.t_font_bold]}>Promo Code</Text>
        <TextInput02
          value={state.promoCode}
          style={styles.v_flex1}
          error={error?.message}
          placeholder="Enter promo code"
          borderStyle={styles.borderStyle}
          inputStyle={{textAlign: "center"}}
          onChangeText={onDispatch("promoCode")}
        />
      </View>
      <View style={styles.v_divider} />
      <View style={[styles.v_item_rows, {justifyContent: "flex-end"}, styles.add_row_margin_top]}>
        <Text style={styles.t_total} />
        <Text style={[styles.t_total]}>Total Due    {totalAmount}</Text>
        {loading && <ActivityIndicator animating size={14} />}
      </View>
      <MayaCard />
    </View>
  );
};

export default MakePayment;
