import SVGIcon from "@assets/svg";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import UnawaProduct from "./unawaProduct";

type LogoProps = {
  logoColor?: string;
  termsColor?: string;
  termsStyle?: any;
  title?: string;
  subtitle?: string;
}

const Logo = (props: LogoProps) => {
  const styles = useStyles();
  const size = useSVGSizeHelper({
    TABLET: { width: 242, height: 40},
    LARGE: { width: 250, height: 47},
    DEFAULT: { width: 262, height: 49},
  });

  return (
    <View style={styles.v_logo_container}>
      <SVGIcon name="signsecure-no-v2" {...size} fill={props.logoColor}  />
      {props.title && <Text style={[styles.t_logo, { color: props.logoColor}]}>{props.title}</Text>}
      {props.subtitle && <Text style={[styles.t_experience, { color: props.logoColor}]}>{props.subtitle}</Text>}
      <UnawaProduct />
    </View>
  );
};

export default Logo;
