import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import { mobileSize } from "@src/utils/screensize-helper";
import React from "react";
import { StyleProp, Text, TextStyle, View, ViewStyle, useWindowDimensions } from "react-native";
import useStyles from "./styles.css";

type Props = {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  content?: string;
}

const LogoTop = (props: Props) => {
  const styles = useStyles();
  const isMobile = mobileSize();
  const { width } = useWindowDimensions();
  const size = React.useMemo(() => {
    const WIDTH = (width - 80) >= 620 ? 620 : width - 80;
    if (isMobile){
      return {
        width: WIDTH >= 200 ? 120 : WIDTH,
        height: 50,
      };
    }

    return {
      width: WIDTH >= 620 ? 260 : WIDTH,
      height: 50,
    };
  },[width]);

  return (
    <View style={[styles.v_logo_top, props.style]}>
      {isMobile ? <SVGIcon name="SignsecureMobileLogo" /> : <SVGIcon name="SignSecure"  {...size} fill={colors.colorPrimary} /> }
      <Text style={[styles.t_logo, props.textStyle]}>{props.content}</Text>
    </View>
  );
};

export default LogoTop;