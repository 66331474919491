import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useUserService } from "@src/ducks/hooks";
import { selectSubscriptionTeamID } from "@src/ducks/slices/user.slice";
import { useFetchPatch } from "@src/utils/api/hooks";
import _ from "lodash";
import React from "react";
import { Modal, Text, View } from "react-native";
import useStyles from "./styles.css";
import { DocumentContext } from "../provider/document";

const AddSuccessful = (props: any) => {
  const styles = useStyles();
  const { onUpdateSubscription } = useUserService();
  const [success, setSuccess] = React.useState(false);
  const { loading, data, runRequest } = useFetchPatch();
  const teamId = useAppSelector(selectSubscriptionTeamID);
  const { setSuccessful } = React.useContext(DocumentContext)

  const onDeleteMember = () => {
    runRequest("/subscription-team/member/remove/" + teamId, {
      "memberEmail": props.email
    });
  };

  const onClose = () => {
    setSuccessful(false)
    // onSetPdfEncryptPassword(true)
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setSuccess(true);
    }
  }, [data]);

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      <View style={styles.container}>
        <View style={[ styles.delete_modal ]} >
          <Text style={styles.textTitle}>Password Encryption Successful</Text>
          <Text style={styles.text_subt}>Your document password is now encrypted and {"\n"} 
            secure. Please remember or securely store the 
            {"\n"}password for future access.</Text>
          <View style={{marginTop: 40, paddingBottom: 20}}>
            <Button
                label="Done"
                style={styles.btn_proceed}
                labelStyle={styles.label_proceed}
                onPress={onClose}
            />
            </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddSuccessful;
