import React from "react";
import { Pressable, View, Text } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import useStyles from "./styles.css";
import colors from "@assets/colors";

type ItemProps = {
  item: string;
  isDone: boolean;
  isActive: boolean;
  disabled: boolean;
  onPress: () => void;
};

const Items = (props: ItemProps) => {
  const styles = useStyles();

  return (
    <View style={styles.v_align_center}>
      <View
        style={[
          styles.outer_circle,
          props.isDone && { backgroundColor: colors.blue, borderColor: colors.blue },
          props.isActive && { borderColor: colors.blue, }
        ]} >
        {props.isDone && <Icon name="check" size={16} color="white" />}
      </View>
      <Text
        style={[
          styles.steps_label,
          props.isDone && { color: colors.blue },
          props.isActive && { color: colors.blue },
        ]}
      >
        {props.item}
      </Text>
    </View>
  );
};


function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.isDone === nextProps.isDone &&
    prevProps.isActive === nextProps.isActive
  );
}

export default React.memo(Items, areEqual);
