import React from "react";
import { Image, Text, View, StyleSheet } from "react-native";
import { Html } from "react-konva-utils";
import colors from "@assets/colors";
import images from "@assets/images";
// import moment from "moment";
import moment from "@src/utils/moment-timezone-helper";

type HTMLSignatureProps = {
  width: number;
  height: number;
  documentId: string;
  signatureDate: number;
};

const HTMLSignature = (props: HTMLSignatureProps) => {
  const { width, height, documentId, signatureDate } = props;
  const borderRedius = height * 0.4782;

  return (
    <Html
      divProps={{
        style: {
          width: width,
          height: height,
          pointerEvents: "none",
          backgroundColor: "transparent"
        },
      }}
    >
      <View pointerEvents={"none"} style={[styles.container, { width, height }]}>
        <View style={[styles.left_view, { width: width * 0.408, borderTopLeftRadius: borderRedius, borderBottomLeftRadius: borderRedius }]}>
          <Image style={{width: width * 0.308, height: height * 0.9}} 
            source={images.signsecurev2LowOpacity} resizeMode="contain" />
        </View>
        <View style={[styles.right_view, { width: width * 0.592, borderTopRightRadius: borderRedius, borderBottomRightRadius: borderRedius }]}>
          <Text style={[styles.t_id_date, { fontSize: height * 0.2 }]}>
            <Text style={styles.t_document_id}>{documentId}</Text> 
            {"  |  "}{moment(signatureDate).format("YYYY.MM.DD  |  hh:mm:ss")}</Text>
        </View>
      </View>
    </Html>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center", borderRadius: 11 },
  left_view: { 
    height: "100%",
    alignItems: "center", justifyContent: "center",
    backgroundColor: colors.gray5, borderTopLeftRadius: 11, borderBottomLeftRadius: 11
  },
  right_view: {
    height: "100%",
    alignItems: "center", justifyContent: "center",
    borderWidth: 1, borderColor: colors.gray5, borderTopRightRadius: 11, borderBottomRightRadius: 11
  },
  t_id_date: {fontFamily: "DMSans", textAlign: "center", color: "#A5A5A5" },
  t_document_id: {fontFamily: "DMSans-Bold" },
});

export default React.memo(HTMLSignature);
