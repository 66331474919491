// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
// import moment from "moment";
import moment from "@src/utils/moment-timezone-helper";
import { ErrorValue, SessionValue, UpgradeSubscription } from "../types";

export interface UserState {
  loading: boolean;
  userdetails: any;
  subscription: any;
  error: ErrorValue;
}

export const initialState: UserState = {
  loading: false,
  userdetails: {},
  subscription: {},
  error: {} as ErrorValue,
} as UserState;

// Slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    success: (state, action) => {
      state.userdetails = action.payload;
    },
    failed: (state, action) => {
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error = {} as ErrorValue;
    },
    subscription: (state, action) => {
      state.subscription = {...state.subscription, ...action.payload};
    },
    cancelSubscription: (state) => {
      state.subscription = {};
      state.userdetails = { ...state.userdetails, activeSubscription: {} };
    },

    updateUserRequest: (state) => {
      state.loading = true;
    },
    updateUserSuccess: (state, action) => {
      state.userdetails = action.payload;
      state.loading = false;
    },
    updateUserFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // UPGRADE SUBSCRIPTION
    upgradeUserSubscription: (state) => {
      state.loading = true;
    },
    upgradeUserSubscriptionSuccess: (state) => {
      state.loading = false;
    },
    upgradeUserSubscriptionFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // RESET USER UPON LOGOUT
    resetUser: () => {
      return initialState;
    },
  },
});

// Actions
export const userActions = {
  fetchUserDetails: createAction(`${userSlice.name}/fetchUserDetails`),
  fetchUserSubscription: createAction(`${userSlice.name}/fetchUserSubscription`),
  success: userSlice.actions.success,
  failed: userSlice.actions.failed,
  resetError: userSlice.actions.resetError,
  subscription: userSlice.actions.subscription,
  cancelSubscription: userSlice.actions.cancelSubscription,

  // Update user details
  updateUserRequest: createAction(
    `${userSlice.name}/updateUserRequest`,
    (params: SessionValue) => ({
      payload: params,
    })
  ),
  updateUserSuccess: userSlice.actions.updateUserSuccess,
  updateUserFailed: userSlice.actions.updateUserFailed,

  // Upgrade User Subscription
  upgradeUserSubscription: createAction(
    `${userSlice.name}/upgradeUserSubscription`,
    (params: UpgradeSubscription) => ({
      payload: params,
    })
  ),
  upgradeUserSubscriptionSuccess: userSlice.actions.upgradeUserSubscriptionSuccess,
  upgradeUserSubscriptionFailed: userSlice.actions.upgradeUserSubscriptionFailed,
  
  resetUser: userSlice.actions.resetUser,
};

// Selectors
export const selectedUserLoading = (state: RootState) => state.user.loading;
export const selectUserDetails = (state: RootState) => state.user.userdetails;
export const selectUserError = (state: RootState) => state.user.error;
export const selectUserFailed = createSelector(
  (state: RootState) => state.user.error,
  (error) => (error.message || "").includes("Unauthorized")
);
export const selectUserEmail = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.email
);
export const selectUserPostion = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.position === "null" ? "" : user?.position
);
export const selectUserDisplayPhoto = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.displayPhoto
);
export const selectUserFullname = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => {
    if(user.givenName){
      return `${user.givenName || ""} ${user.middleName || ""} ${user.lastName || ""}`.trim();
    }
    const match = user?.email?.match(/^([^@]+)@/);
    return match ? match[1] : user.email;
  }
);

export const selectUsernoMiddleNameFullname = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => {
    if(user.givenName){
      return `${user.givenName || ""} ${user.lastName || ""}`.trim();
    }else{
      const match = user?.email?.match(/^([^@]+)@/);
      return match ? match[1] : user.email;
    }
  }
);

export const selectUserFirstName = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => {
    if(user.givenName){
      return `${user.givenName || ""}`.trim();
    }else{
      const match = user?.email?.match(/^([^@]+)@/);
      return match ? match[1] : user.email;
    }
  }
);
export const selectPhoneNumber = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.phoneNumber === "null" ? "" : `${user.phoneNumber || ""}`.trim()
);
export const selectRefferalCode = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user.referralCode || "",
);
export const selectRefferalPoints = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.referralPoints ?? 0,
);
export const selectTeamMembers = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user.members || []
);
export const selectSubscriptionName = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.name ?? "Free Plan"
);
export const selectSubscriptionMaxUsers = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.maxUsers ?? 0
);
export const selectSubscriptionValid48Hours = createSelector(
  (state: RootState) => state.user.subscription,
  (state) => {
    const timestamp = state.updatedAt;
    const dateFrom = moment(timestamp);
    const dateTo = dateFrom.clone().add(48, "hours");
    return moment().isBefore(dateTo);
  }
);
export const selectSubscriptionFullSlot = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => {
    const maxUsers = user?.subscriptionPlan?.maxUsers ?? 0;
    const members = user?.members ?? [];
    return members.length >= maxUsers;
  }
);
export const selectSubscriptionID = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.id ?? ""
);
export const selectSubscriptionType = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.type ?? ""
);
export const selectSubscriptionDesc = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.pageDescription,
);
export const selectSubscriptionStorageLimit = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.storageLimitInMB ?? 0
);
export const selectSubscriptionSimultaneousUpload = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.subscriptionPlan?.simultaneousUpload ?? 1
);
export const selectSubscriptionExpiration = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user.expiration ? moment(user.expiration, "DD MM YYYY").format("MMMM DD YYYY") : ""
);
export const selectSubscriptionActive = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user.isActive || false
);
export const selectSubscriptionTeamName = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user.name || ""
);
export const selectSubscriptionTeamID = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user.id
);
export const selectMayaSaveCredential = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => ({customerId: user.mayaCustomerId, cardToken: user.mayaCardtokenId})
);
export const selectSubscriptionRole = createSelector(
  (state: RootState) => state.user.userdetails,
  (user) => user?.activeSubscription?.role ?? ""
);
export const selectSubscriptionCancelled = createSelector(
  (state: RootState) => state.user.subscription,
  (user) => user?.status === "Cancelled"
);
export const selectStorage = createSelector(
  (state: RootState) => state.user.subscription,
  (plan) => {
    const totalStorage = plan?.subscriptionPlan?.storageLimitInMB ?? 0;
    const remainingStorage = plan?.remainingStorage ?? 0;

    return {
      used: (totalStorage - remainingStorage) / 1000,
      totalStorage: totalStorage / 1000,
    };
  }
);

// Reducer
export default userSlice.reducer;
