import PopupMenuProvider from "@src/components/popup-menu/provider";
import { gtag } from "ga-gtag";
import React from "react";
import { DrawerProvider } from "./drawer-provider";
import MainScreen from "./main-screen";

const DrawerScreen = () => {
  React.useEffect(() => {
    gtag("event", "homepage");
  }, []);
  
  return (
    <DrawerProvider>
      <PopupMenuProvider>
          <MainScreen />
      </PopupMenuProvider>
    </DrawerProvider>
  );
};

export default DrawerScreen;
