import { CommonActions, useNavigation } from "@react-navigation/native";
import { AuthContext } from "@src/AuthProvider";
import Loading from "@src/components/loading";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService, useSignsecureService } from "@src/ducks/hooks";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { selectedDocument } from "@src/ducks/slices/dashboard.slice";
import {
  selectedAffixDocument, selectedApproveDocument, selectedCancelDocument,
  selectedCustomSignatureID,
  selectedDeclineDocument, selectedRejectDocument, selectedSignSecureError,
  selectedViewDocument
} from "@src/ducks/slices/signsecure.slice";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import _ from "lodash";
// import moment from "moment";
import moment from "@src/utils/moment-timezone-helper";
import React, { Dispatch, SetStateAction, createContext, useCallback, useState } from "react";
import * as Types from "./types";
import ShareDocument from "../share-document/share-document";

type SelectedItemValue = {
  layerId: string | number;
  fieldId: number;
  type: string;
  value?: any
};

type SignatureValue = {
  signature: string;
  signatureType: string;
  signatureId: string;
}

interface ContextValue {
  screen: string;
  document: any;
  showSignature: boolean;
  selectedItem: any,
  showcancel: boolean;
  showdecline: boolean;
  showreject: boolean;
  showAuditTrail: boolean;
  showShareDocument: boolean;
  showDocumentDetails: boolean;
  showTextbox: boolean;
  showQuickSigning: boolean;
  onSetSignature: (params: SignatureValue) => void;
  onCheckBoxItem: (params: SelectedItemValue) => void;
  onTextBoxFocus: (params: SelectedItemValue) => void;
  onTextBoxItem: (params: SelectedItemValue) => void;
  onActionButton: (type: string) => void;
  onShowModal: (value: SelectedItemValue) => void;
  onShowQuickSigning: (value: SelectedItemValue) => void;
  onShowTextBoxModal: (value: SelectedItemValue) => void;
  setShowQuickSigning: Dispatch<SetStateAction<boolean>>;
  setShowSignature: Dispatch<SetStateAction<boolean>>;
  setCancelModal: Dispatch<SetStateAction<boolean>>;
  setDeclineModal: Dispatch<SetStateAction<boolean>>;
  setShowAuditTrail: Dispatch<SetStateAction<boolean>>;
  setShowShareDocument: Dispatch<SetStateAction<boolean>>;
  setRejectModal: Dispatch<SetStateAction<boolean>>;
  setShowDocumentDetails: Dispatch<SetStateAction<boolean>>;
  setShowTexbox: Dispatch<SetStateAction<boolean>>;
  setScreen: Dispatch<SetStateAction<string>>;
}

const SignatureContext = createContext<ContextValue>({} as ContextValue);

const SignatureProvider = ({ children }: Types.DocumentProviderProps) => {
  const navigation = useNavigation();
  const [screen, setScreen] = useState<string>("");
  const { onDrawerEnable } = useDashboardService();
  const selected = useAppSelector(selectedDocument);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const document = useAppSelector(selectedViewDocument);
  const { onActiveTab } = React.useContext(DrawerContext);
  const { onErrorMessage } = React.useContext(AuthContext);
  const affixDocument = useAppSelector(selectedAffixDocument);
  const signatureId = useAppSelector(selectedCustomSignatureID);
  const cancelDocument = useAppSelector(selectedCancelDocument);
  const rejectDocument = useAppSelector(selectedRejectDocument);
  const declineDocument = useAppSelector(selectedDeclineDocument);
  const approveDocument = useAppSelector(selectedApproveDocument);
  const signsecureError = useAppSelector(selectedSignSecureError);
  const [showcancel, setCancelModal] = useState<boolean>(false);
  const [showdecline, setDeclineModal] = useState<boolean>(false);
  const [showreject, setRejectModal] = useState<boolean>(false);
  const [showSignature, setShowSignature] = useState<boolean>(false);
  const [showAuditTrail, setShowAuditTrail] = useState<boolean>(false);
  const [showShareDocument, setShowShareDocument] = useState<boolean>(false);
  const [showQuickSigning, setShowQuickSigning] = useState<boolean>(false);
  const [showDocumentDetails, setShowDocumentDetails] = useState<boolean>(false);
  const { viewDocumentRequest, viewDocumentChange, onResetSignSecure } = useSignsecureService();
  const [selectedItem, setSelectedItem] = useState<SelectedItemValue>({} as SelectedItemValue);
  const [showTextbox, setShowTexbox] = useState<boolean>(false);

  const onShowModal = useCallback(
    (params: SelectedItemValue) => {
      setSelectedItem(params);
      setShowSignature(true);
    },
    [selectedItem]
  );

  const onShowQuickSigning = useCallback(
    (params: SelectedItemValue) => {
      setSelectedItem(params);
      setShowQuickSigning(true);
    },
    [selectedItem]
  );

  const onShowTextBoxModal = useCallback(
    (params: SelectedItemValue) => {
      setSelectedItem(params);
      setShowTexbox(true);
    },
    [selectedItem]
  );

  const onActionButton = useCallback(
    (type: string) => {
      switch(type){
      case "Audit Trail":
        setShowAuditTrail(true);
        break;
      case "Document details":
        setShowDocumentDetails(true);
        break;
      case "Share Document":
        setShowShareDocument(true);
        break;
      case "Decline to sign":
        setDeclineModal(true);
        break;
      case "Cancel document":
        setCancelModal(true);
        break;
      case "Reject document":
        setRejectModal(true);
        break;
      }
    },
    []
  );

  const onSetSignature = useCallback(
    (signature: SignatureValue) => {
      const cloneDocument = _.cloneDeep(document);
      const nlayers: any = cloneDocument.workflow.signatures.slice();
      const nfields = nlayers[selectedItem.layerId];
      nfields[selectedItem.fieldId] = {
        ...nfields[selectedItem.fieldId],
        ...signature,
        signatureId,
        signatureDate: +moment(),
      };

      nlayers[selectedItem.layerId] = nfields;
      cloneDocument["workflow"]["signatures"] = nlayers;
      cloneDocument["signature"] = {...signature, signatureId};

      viewDocumentChange(cloneDocument);
      setShowSignature(false);
    },
    [selectedItem, document]
  );

  const onCheckBoxItem = useCallback(
    (selected: any) => {
      const cloneDocument = _.cloneDeep(document);
      const nlayers: any = cloneDocument.workflow.signatures.slice();
      const nfields = nlayers[selected.layerId];
      nfields[selected.fieldId] = {
        ...nfields[selected.fieldId],
        isChecked: !nfields[selected.fieldId].isChecked
      };

      nlayers[selected.layerId] = nfields;
      cloneDocument["workflow"]["signatures"] = nlayers;

      viewDocumentChange(cloneDocument);
      setShowSignature(false);
    },
    [document]
  );

  const onTextBoxFocus = useCallback(
    (selected: any) => {
      const cloneDocument = _.cloneDeep(document);
      const nlayers: any = cloneDocument.workflow.signatures.slice();
      const nfields = nlayers[selected.layerId];
      nfields[selected.fieldId] = {
        ...nfields[selected.fieldId],
        isFocus: selected.value
      };

      nlayers[selected.layerId] = nfields;
      cloneDocument["workflow"]["signatures"] = nlayers;

      viewDocumentChange(cloneDocument);
      setShowSignature(false);
    },
    [document]
  );

  const onTextBoxItem = useCallback(
    (selected: any) => {
      const cloneDocument = _.cloneDeep(document);
      const nlayers: any = cloneDocument.workflow.signatures.slice();
      const nfields = nlayers[selected.layerId];

      if(selected.value.textWidth > 260){
        nfields[selected.fieldId] = {
          ...nfields[selected.fieldId],
          isFocus: false,
          text: selected.value.input,
          config: {
            ...nfields[selected.fieldId].config,
            width: selected.value.textWidth
          },
          inputConfig: {
            ...nfields[selected.fieldId].inputConfig,
            width: selected.value.textWidth
          },
        };
      }else{
        nfields[selected.fieldId] = {
          ...nfields[selected.fieldId],
          isFocus: false,
          text: selected.value.input,
          config: {
            ...nfields[selected.fieldId].config,
            width: 200
          },
          inputConfig: {
            ...nfields[selected.fieldId].inputConfig,
            width: 200
          },
        };
      }

      nlayers[selected.layerId] = nfields;
      cloneDocument["workflow"]["signatures"] = nlayers;

      viewDocumentChange(cloneDocument);
      setShowSignature(false);
    },
    [document]
  );

  const value = {
    screen, setScreen,
    document,
    showSignature,
    selectedItem,
    showTextbox,
    onShowModal,
    onShowQuickSigning,
    setShowSignature,
    setShowTexbox,
    onSetSignature,
    onCheckBoxItem,
    onTextBoxFocus,
    onTextBoxItem,
    onShowTextBoxModal,
    showcancel, setCancelModal,
    showdecline, setDeclineModal,
    showreject, setRejectModal,
    showAuditTrail, setShowAuditTrail,
    showShareDocument, setShowShareDocument,
    showDocumentDetails, setShowDocumentDetails,
    showQuickSigning, setShowQuickSigning,
    onActionButton,
  };

  React.useEffect(() => {
    viewDocumentRequest(selected);
    onDrawerEnable(false)
    return () => {
      onResetSignSecure();
      navigation.dispatch(CommonActions.setParams({ activeTab: undefined, w: undefined }));
    };
  }, [])

  React.useEffect(() => {
    if(!_.isEmpty(signsecureError.message)){
      onErrorMessage(signsecureError?.message ?? "");
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: isLoggedIn ? "Home" : "Signin" },
          ],
        })
      );
    }
    if(!_.isEmpty(signsecureError) && _.isEmpty(document)){
      onActiveTab("Dashboard");
    }
    if(!_.isEmpty(cancelDocument)){
      setScreen("CANCELLED");
    }
    if(!_.isEmpty(declineDocument)){
      setScreen("DECLINED");
    }
    if(!_.isEmpty(rejectDocument)){
      setScreen("REJECTED");
    }
    if(!_.isEmpty(affixDocument)){
      setScreen("SIGNED");
    }
    if(!_.isEmpty(approveDocument)){
      setScreen("APPROVED");
    }
  },[affixDocument, cancelDocument, declineDocument, rejectDocument, approveDocument, signsecureError.message])

  return (
    <SignatureContext.Provider value={value}>
      {selected && _.isEmpty(document) ? <Loading /> : children}
      <ShareDocument 
        visible={showShareDocument}
      />
    </SignatureContext.Provider>
  );
};

export { SignatureContext, SignatureProvider };

