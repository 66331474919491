import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useUserService } from "@src/ducks/hooks";
import { selectSubscriptionTeamID } from "@src/ducks/slices/user.slice";
import { useFetchPatch } from "@src/utils/api/hooks";
import _ from "lodash";
import React, { useContext } from "react";
import { Modal, Text, View, } from "react-native";
import useStyles from "./styles.css";
import AddInfo from "./add-new-team-text";
import GeneratePassword from "./generatePassword";
import AddSuccessful from "./password-success";
import { DocumentContext } from "../provider/document";
import PasswordFields from "./password-fields";

interface ErrorProps {
  password: string;
  confirmpassword: string;
}

const ErrorValue = {
 password: "",
 confirmpassword: "",
};


const AddEncrpytPassword = (props: any) => {
  const styles = useStyles();
  const [success, setSuccess] = React.useState(false);
  const { loading, data, runRequest } = useFetchPatch();
  const teamId = useAppSelector(selectSubscriptionTeamID);
  const { setSuccessful, setPasswordEncrpyt, onSetPdfEncryptPassword, setCheckBoxTick,generatePassword,setGeneratePassword, setGeneratePasswordTick,pdfpasswords, setPDFPassword} = React.useContext(DocumentContext)



  const errorRef = React.useRef<any>(null);
   const [confirmPdfPassword, setConfirmPdfPassword] = React.useState<string>("");
    const [isGeneratedPassword, setIsGeneratedPassword] = React.useState(false);
   const [error, setError] = React.useState<ErrorProps>(ErrorValue);


  const onDeleteMember = () => {
    runRequest("/subscription-team/member/remove/" + teamId, {
      "memberEmail": props.email
    });
  };

  
  const onSubmit = () => {

    const newError: any = {};
    if(!isGeneratedPassword){
      if (!pdfpasswords) {
        newError.password = "Password is required.";
      }
  
      if (!confirmPdfPassword) {
        newError.confirmpassword = "Confirm Password is required.";
      }
  
      if (pdfpasswords !== confirmPdfPassword) {
        newError.confirmpassword = "Password not match";
      }
  
      if (pdfpasswords == confirmPdfPassword) {
        onSetPdfEncryptPassword(pdfpasswords)
      }

   
    }
  
     setError(newError);

     if (_.isEmpty(newError)) {
      if(isGeneratedPassword){
        onSetPdfEncryptPassword(generatePassword);
      }else{
        if (pdfpasswords == confirmPdfPassword) {
          onSetPdfEncryptPassword(pdfpasswords)
        }
      }
     
      setSuccessful(true)
      setPasswordEncrpyt(false) 
    }
  }

  const onClose = () => {
    if(isGeneratedPassword == true){
        <PasswordFields />
        setGeneratePasswordTick(false)
        setIsGeneratedPassword(false)
        setPasswordEncrpyt(false)
        setCheckBoxTick(false)
    } else if(!isGeneratedPassword){
        <PasswordFields />
        setPasswordEncrpyt(false)
        setCheckBoxTick(false)
    } 
    else {
      <PasswordFields />
        setPasswordEncrpyt(false)
        onSetPdfEncryptPassword("")
        setIsGeneratedPassword(true)
        setCheckBoxTick(false)
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setSuccess(true);
    }
  }, [data]);

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={"none"}
      onRequestClose={props.onClose}
      supportedOrientations={[
        "portrait",
        "portrait-upside-down",
        "landscape",
        "landscape-left",
        "landscape-right",
      ]}
    >
      
      <View style={styles.container}>
        <View style={[ styles.email_sent_modal ]} >
          <AddInfo />
          <GeneratePassword isGeneratePassword={setIsGeneratedPassword} generatePassword={setGeneratePassword}/>
          {!isGeneratedPassword  &&  <PasswordFields password={setPDFPassword} confirmPassword={setConfirmPdfPassword} error={error}/> }
          <View style={styles.btn_alignment}>
          <Button
              onPress={onSubmit}
              // isLoading={loading || loadingPatch}
              style={styles.btn_proceed}
              labelStyle={styles.label_proceed}
              label={"Encrypt"}
            />
            <View style={styles.v_width} />
            <Button
            
              label="Cancel"
              style={styles.btn_cancel}
              labelStyle={styles.label_cancel}
              onPress={onClose}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddEncrpytPassword;
